.margin-dot-green {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $success;
  margin-right: 5px;
  margin-bottom: 0.1rem;
}

.margin-dot-blue {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $dark-blue;
  margin-right: 5px;
  margin-bottom: 0.1rem;
}

.margin-dot-yellow {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f8ce4c;
  margin-right: 5px;
  margin-bottom: 0.1rem;
}

.margin-dot-orange {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffbf56;
  margin-right: 5px;
  margin-bottom: 0.1rem;
}
.margin-dot-red {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $red;
  margin-right: 5px;
  margin-bottom: 0.1rem;
}

.export-button {
  padding-right: 19px;
  .download-link {
    color: #a79779;
    font-size: 14px;
    font-weight: 600;
    .pdf-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    &:focus,
    &:hover {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }
}
.download-button {
  // padding-right: 19px;
  margin-top: 4px;
  margin-left: 1.5rem;
  .download-link {
    color: #a79779;
    font-size: 14px;
    font-weight: 600;
    .pdf-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    &:focus,
    &:hover {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }
}
