@import '../mixins/respond';

.supportbar-b2c {
  height: 60px;
  width: 100%;
  background: white;
  display: flex;
  z-index: 9;

  padding-left: 6.25rem;
  padding-top: 1.2rem;

  .supportbar-img {
    width: 8rem;
  }
}

.b2c-page {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 100vh;

  @include respond('xsmall') {
    max-width: unset;
  }

  @include respond('small') {
    max-width: unset;
  }

  @include respond('medium') {
    max-width: 720px;
  }

  @include respond('large') {
    max-width: 720px;
  }

  @include respond('ipad') {
    max-width: 1110px;
  }

  @include respond('xlarge') {
    max-width: 1140px;
  }

  @include respond('xxlarge') {
    max-width: 1140px;
  }

  .b2c-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 2rem;
    padding-top: 2rem;

    .rolzo-logo {
      width: 130px;
      height: 16.5px;
      cursor: pointer;
    }

    .currency-select {
      display: flex;
      align-items: baseline;
      justify-content: end;
      cursor: pointer;
    }

    .currency-logo {
      width: 14px;
      height: 10.6px;
    }

    .currency-label {
      margin-left: 5px;
      font-size: 16px;
    }

    .currency-arrow {
      padding-left: 2px;
      top: 3px;
      position: relative;
    }

    .show {
      transform: rotate(180deg);
      padding-right: 2px;
      padding-left: 0;
    }

    .currency-dropdown {
      width: 66px;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      background-color: #fefefe;
      box-sizing: border-box;
      cursor: pointer;
      float: left;
      font-size: 14px;
      font-weight: 400;
      outline: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
      -webkit-tap-highlight-color: transparent;
      z-index: 1;
    }

    .currency-item {
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding-bottom: 2px;
      width: 100%;
    }

    .first {
      padding-top: 4px;
    }

    .currency-item:hover,
    .currency-item.focus,
    .currency-item.selected.focus {
      background-color: #f6f6f6;
    }
  }

  .b2c-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .map-view {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 450px;
    width: 100%;
  }

  .left-container {
    flex: 0 0 40%;
  }

  .right-container {
    // margin-top: 6.5px;
    flex: 0 0 57%;

    .vehicle-card {
      flex-wrap: nowrap;
      max-width: 635px;
      padding: 0px 30px 15px 25px;

      margin: 6.5px 0;
      padding-right: 30px;
      flex-direction: row;
      border-radius: 2px;
      box-shadow: none;
      // border: 1px solid #0c0c0c;
      // border-radius: 2px;
      overflow: hidden;

      .active {
        box-shadow: none;
        border: 1px solid #0c0c0c;
      }

      box-shadow: $default-shadow;
    }
    .vehicle-img-wrap {
      max-width: 210px;
      height: 100%;
      padding: 25px 0 52px 0px;
      margin: auto;
      width: 230px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
    }
    .vehicle-detail-card {
      .card-body {
        padding: 1.25rem !important;
      }
    }
    .card-body {
      padding-left: 6px;
      flex: 1 1 auto;
      min-height: 1px;
      // padding: 40px 15px;

      width: 100%;
    }
    .card-action {
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      padding: 52px 0 36px;
    }
    .vehicle-price-width {
      max-width: fit-content;
      white-space: nowrap;
    }
    .vehicle-title-limit {
      @media (min-width: 1520px) and (max-width: 1540px) {
        width: 12rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      /* Above 1540px and up to 1570px */
      @media (min-width: 1541px) and (max-width: 1570px) {
        width: 13rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      @media (min-width: 1205px) and (max-width: 1500px) {
        width: 12rem !important;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      @media (max-width: 1205px) {
        width: 12rem !important;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .passenger-name-div {
      // max-width: 177px;
      max-width: none;
    }
    .capacity-div {
      // max-width: 177px;
      max-width: none;
    }
    .phone-number-div {
      // max-width: 177px;
      max-width: none;
    }

    .react-tel-input .form-control {
      // font-family: 'AvenirNext';
      font-family: $font-family-base;
    }

    .service-card {
      margin: 6.5px 0;
      padding: 30px;
      border-radius: 2px;
      border: 1.5px solid rgba(139, 149, 158, 0.1);
      overflow: hidden;
      width: auto;
      .card-body {
        padding: 0 !important;
        padding-left: 6px !important;
      }
    }
    .sub-header {
      margin-bottom: 24px !important;

      &.no-margin {
        margin-bottom: 0px !important;
      }
    }
    .sub-header-email {
      font-size: 20px;
      margin-bottom: 0.75rem !important;
    }

    .form-block {
      // padding: 1.625rem 0 2.25rem 0;
      padding: 2.25rem 0 2.25rem 0;
    }

    .form-row {
      // width: 82%;
      // max-width: 505px;
      width: 100%;
      max-width: none;
      .form-benefits {
        width: 100%;
        // max-width: 505px;
        max-width: none;
      }
      .form-benefits-carRental {
        width: 89%;
        // max-width: 505px;
        max-width: none;
      }
    }

    .passenger-information-special {
      padding: 9px 0px 0px 0px !important;
    }

    .messageContainer {
      .message {
        background-color: #ffffff;
        background-position: top 0.8px left 0px;
        background-size: 20px 15px;
        font-size: 14px;
        font-weight: 400;
        background-repeat: no-repeat;
        padding-left: 18px;
        border-radius: 4px;
      }
    }
  }

  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    margin: 1rem 0 1rem 0;
  }

  .step {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    transition: 0.5s;
    border-radius: 4px;
    user-select: none;
  }

  .step:first-child {
    padding-left: 0;
  }

  .step:last-child {
    padding-right: 0;
  }

  .step .label {
    color: rgba(139, 149, 158, 0.5);
  }

  .step.active .label {
    color: #0c0c0c;
  }

  .step.completed .label {
    color: #0c0c0c;
  }

  .step.completed .label:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .step.active .label:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .step .icon {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 12px;
    border: 1px solid rgba(139, 149, 158, 0.5);
    color: rgba(139, 149, 158, 0.5);
    margin-right: 0.5rem;
  }

  .step.active .icon {
    border: 1px solid #0c0c0c;
    color: #0c0c0c;
  }

  .step.completed .icon {
    border: 1px solid #0c0c0c;
    background-color: #0c0c0c;
    color: white;
  }

  .step-divider {
    flex: 1;
    border-top-style: solid;
    border-color: #bdbdbd;
    border-top-width: 1px;
  }

  .step-divider.completed {
    border-color: #0c0c0c;
  }

  .remove-button-stops-b2c {
    position: absolute;
    top: 30.5px;
    right: -22px;
    height: 23px;
    width: 23px;
    border-radius: 18px;
    cursor: pointer;
  }
}

.success-msg-modal-b2c {
  // position: fixed;
  // width: 100vw;
  // height: 100vh;
  // color: $white;
  // z-index: 100;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-content: center;
  // top: 0;
  // left: 0;
  // background: transparent;
  // overflow-y: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  // height: 100vh;
  // max-width: 600px;
  text-align: center;
  padding: 20px;
  background: transparent;
  overflow-y: auto;

  img {
    display: block;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 0 auto 35px auto;
  }

  .success-msg-header {
    // font-size: 20px;
    font-size: 24px;
    color: $black;
    text-align: center;
    font-weight: 400;
    //margin-bottom: 25px;
  }

  .success-msg-description {
    font-size: 15px;
    // color: $dark-grey;
    color: rgba($dark-grey-2, 0.7);
    text-align: center;
    white-space: pre-wrap;
    line-height: 26px;
  }

  .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }

  .content-wrap {
    display: flex;
    // max-width: 500px;
    max-width: 531px;
    flex-direction: column;
    justify-content: center;
  }

  .content-div {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .success-msg-modal-b2c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 100%;
    // height: 100vh;
    // max-width: 600px;
    text-align: center;
    padding: 20px;
    background: transparent;
    overflow-y: auto;

    img {
      display: block;
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin: 0 auto 35px auto;
    }

    .success-msg-header {
      // font-size: 20px;
      font-size: 24px;
      color: $black;
      text-align: center;
      font-weight: 400;
      //margin-bottom: 25px;
    }

    .success-msg-description {
      font-size: 15px;
      // color: $dark-grey;
      color: rgba($dark-grey-2, 0.7);
      text-align: center;
      white-space: pre-wrap;
      line-height: 26px;
    }

    .btn {
      text-align: center;
      font-weight: 500;
      font-size: 15px;
    }

    .content-wrap {
      display: flex;
      // max-width: 500px;
      max-width: 531px;
      flex-direction: column;
      justify-content: center;
    }

    .content-div {
      display: flex;
      justify-content: center;
    }
  }
}
.continue-round {
  border-radius: 20px;
}
.max-width-fit-content {
  max-width: fit-content;
}
.b2c-empty-search {
  padding: 55px 45px;
  border-radius: 2px;
  margin-top: 6.5px;
  box-shadow: $card-shadow;

  h5 {
    margin-bottom: 0.75rem;
    font-size: 20px;
    color: $dark-2;
    font-weight: normal;
  }

  .b2c-empty-description {
    font-size: 15px;
    font-weight: 400;
    color: $dark-grey;
    white-space: pre-wrap;
  }

  .b2c-empty-info {
    font-size: 16px;
    margin-top: 1.2rem;
    color: $dark-2;
  }
}

.b2c-form-container {
  // padding-top: 1.625rem !important;
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
  // padding-bottom: 0.625rem !important;
  &.passenger-info {
    padding-top: 1.625rem !important;
  }

  .form-block {
    .form-row {
      width: 82%;
      // max-width: 505px;
      max-width: none;
      .form-benefits {
        width: 100%;
        // max-width: 505px;
        max-width: none;
      }
    }
  }

  .form-block {
    .form-benefits {
      width: 100%;
      // max-width: 505px;
      max-width: none;
    }
  }

  .container {
    max-width: none;
  }

  .sub-header {
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .b2c-container {
    display: block !important;
  }

  .b2c-page {
    .steps {
      margin: 1rem 1rem 4rem 1rem !important;
    }
    .step {
      flex-direction: column;
      width: calc(100vw - 100%);
      text-align: center;
      position: relative;
      width: calc(100vw - 93%);
    }

    .step-divider {
      margin: 0 -18px;
    }

    .step:first-child {
      padding-left: 0.5rem !important;
    }

    .step:last-child {
      padding-right: 0.5rem !important;
    }

    .step .label {
      position: absolute;
      top: 50px;
      left: 0;
      text-align: center;
      font-size: 12px;
    }

    .step .icon {
      margin-right: 0 !important;
    }
  }

  .child-seat {
    display: flex;
    justify-content: space-between;
  }

  .left-container {
    .booking-preview-info {
      .booking-preview-info-section {
        padding: 20px 24px 20px 24px !important;
      }
    }
  }

  .right-container {
    .vehicle-img-wrap {
      padding: 5px 10px 0 !important;
    }

    .card-action {
      padding: 0 !important;
    }

    .react-tel-input .form-control {
      // font-family: 'AvenirNext';
      font-family: $font-family-base;
    }
    .booking-preview-info {
      .booking-preview-info-section {
        padding: 20px 24px 20px 24px !important;
      }
    }

    .form-row {
      width: 100% !important;
    }
  }

  .b2c-empty-search {
    padding: 24px !important;

    .submit-btn {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100% !important;
    }
  }
}
