.searchable-dropdown {
    position: relative;
    border-radius: 2px;
  }
  
  .search-input {
    width: 200px;
    height: 50px;
    padding: 0 10px;
    background-color: #fbfbfb;
    color: #0c0c0c;
    font-size: 14px;
    font-weight: 400;
    border: 0.5px solid transparent;
    border-radius: 0%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
    outline: black;
  }
  
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 0%;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(#000000, 0.1);
  }
  
  .dropdown-option {
    cursor: pointer;
    padding: 8px 10px 6px;
    font-weight: 400;
    font-size: 14px;
    color: black;
    font-family: 'AvenirNext', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  
  .dropdown-option:hover {
    background-color: #f5f5f5;
  }
  
  .dropdown-option:focus {
    outline: none;
    background-color: #f5f5f5;
  }
  

   