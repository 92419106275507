.selectOptionButton {
  background-color: inherit;
  margin-left: 1px;
  img {
    float: right;
    width: 20px;
    margin-top: 5px;
    margin-right: 5px;
  }
}

.selectOptionButtonCurrency {
  // background-color: #fcfcfc;
  margin-left: 1px;
  img {
    float: right;
    width: 20px;
    margin-right: 5px;
  }
}
