@media (max-width: 768px) {
  .settings-page {
    padding: 20px 15px 25px 0px;
    // padding: 20px 15px 25px 0px !important;
    // height: 100px;
    .account-header {
      display: flex !important;
      justify-content: center;
      align-items: center;
      // border-bottom: 1px solid rgba($dark-grey, 0.2);
      .back-btn {
        left: 0% !important;
        top: 0% !important;
        margin-top: 0.6px !important;
        margin-bottom: 0px !important;
      }
    }
  }
  .useless-class {
    .settings-row {
      border-bottom: 1px solid rgba($dark-grey, 0.2);
      margin-left: 0.2rem;
      margin-right: 0.4rem;
    }
    .NoBorderBottom {
      border-bottom: none !important;
    }
    .settings-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 16px;
      margin-bottom: 0.8rem !important;
      margin-top: 0.8rem !important;
      color: $black !important;
      padding-left: 0% !important;

      img {
        max-height: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .NotificationSettings {
    .BookingUpdates {
      span {
        font-size: 15px !important;
        color: black;
      }
    }
    .icons {
      .email-icon {
        width: 12px !important;
        vertical-align: baseline;
        margin-right: 5px;
      }
      img {
        width: 11px;
        vertical-align: baseline;
        margin-right: 5px;
      }
      span {
        color: #8b959e;
        font-size: 10px;
      }
    }
    .checkBox-Center {
      margin: 0% 20%;
    }
    .maxWidth {
      width: 55% !important;
    }
    .allignCheckBoxMobile {
      padding-top: 1.5rem;
    }
    thead {
      display: table-caption !important;
      th {
        border-bottom: none !important;
        width: 100%;
      }
    }

    tbody {
      tr {
        td {
          border: none !important;
        }
      }
    }
    th {
      border: none !important;
      td {
        border: none !important;
      }
    }

    .custom-control-label:after {
      border-radius: 2px;
      background-color: #fbfbfb;
      border: 1px solid #8b959e;
    }
    .custom-control-input:checked ~ .custom-control-label:after {
      border-color: black;
      background-size: 9px 11px;
    }
  }
  .no-top {
    padding-top: 0px !important;
  }

  .preview-card {
    height: 14rem;
    width: 19.7rem;
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(139, 149, 158, 0.3);
    padding: 1.6rem;
    border-radius: 2px;
    margin-top: 1rem;
    align-items: center;
    box-shadow: $default-shadow;
    padding-bottom: 0px !important;
  }

  .preview-card .top-image-container {
    width: 80px;
    height: 80px;
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
  }
  .preview-card .bottom-image-container {
    width: 80px;
    height: 80px;
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .preview-card .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: black;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .preview-card .top-image {
    max-height: 100%; /* Ensure the image fits within the reserved space */
  }

  .preview-card .bottom-image {
    max-height: 100%; /* Ensure the image fits within the reserved space */
    align-self: center;
  }

  .payment-currency-settings {
    .react-currencySelect-container {
      width: 100%;
      font-size: 14px;
      background: transparent;
      z-index: 0 !important;
      &.is-invalid {
        .react-currencySelect__control {
          border: 1px solid $danger;
          background: transparent;
          &:hover,
          &:focus {
            border-color: $red;
          }
        }
      }

      .react-currencySelect__control {
        height: 50px;
        border-radius: 2px;
        border: $form-control-border;
        box-shadow: $default-shadow;
        // background-color: $light-2;
        background-color: red;
        padding: 0;
        background: transparent;
        &--is-focused,
        &--menu-is-open,
        &:hover {
          border-color: $black;
          background-color: transparent;
        }

        &--menu-is-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &--is-disabled {
          background-color: $light;
        }

        .react-currencySelect__indicator-separator {
          display: none;
        }

        .react-currencySelect__dropdown-indicator {
          padding: 0 5px;
          img {
            width: 17px;
            height: 17px;
          }
        }
        .react-currencySelect__dropdown-indicator-creatable {
          padding: 0 10px !important;
          img {
            width: 10px !important;
            height: 10px !important;
          }
        }

        .react-currencySelect__single-value {
          color: $black;
        }

        .select-multiple-value {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        // .react-currencySelect__value-container-hide{
        //   z-index: 0 !important;
        // }
        .react-currencySelect__value-container {
          z-index: -1;
          &--is-multi {
            flex-wrap: nowrap;

            .select-all-btn {
              display: flex;
              align-items: center;
              overflow: hidden;
              cursor: pointer;

              &:before {
                content: '';
                display: inline-block;
                width: 15px;
                min-width: 15px;
                height: 15px;
                background-image: url('/images/icons/radio.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 15px;
              }

              &.selected-all {
                &:before {
                  background-image: url('/images/icons/radio-checked.svg');
                }
              }

              .btn-title {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }

        .react-currencySelect__placeholder {
          color: rgba($black, 0.3);
          z-index: 1;
        }
      }
      .react-currencySelect__control--menu-is-open {
        .react-currencySelect__value-container {
          z-index: 0;
        }
      }
      .react-currencySelect__menu {
        margin-top: 7px;
        border: 1px solid rgba($black, 0.1);
        box-shadow: none;
        border-radius: 0;
        z-index: -2;
        .react-select__option {
          padding: 10px;
          color: $black;
          cursor: pointer;
          border: none;
          &:hover,
          &--is-focused {
            background-color: rgba($black, 0.1);
          }

          &--is-selected {
            color: $white;
            background-color: $black;

            &:hover {
              background-color: rgba($black, 0.1);
            }
          }
        }
        .react-currencySelect__option {
          background-color: $white;
          &:hover,
          &--is-focused {
            background-color: rgba($black, 0.1);
          }
        }
        .react-select-filters__option {
          padding: 10px;
          color: $black;
          cursor: pointer;
          border: none;
          &:hover,
          &--is-focused {
            background-color: rgba($black, 0.1);
          }

          &--is-selected {
            color: $white;
            background-color: $black;

            &:hover {
              background-color: rgba($black, 0.1);
            }
          }
        }

        &-list {
          padding: 0;
          margin-left: -1px;

          &--is-multi {
            .react-select__option {
              display: flex;
              align-items: center;

              &:before {
                content: '';
                display: inline-block;
                width: 15px;
                min-width: 15px;
                height: 15px;
                background-image: url('/images/icons/radio.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 15px;
              }

              &--is-selected {
                color: $black;
                background-color: transparent;

                &:hover,
                &--is-focused {
                  background-color: rgba($brown, 0.3);
                }

                &:before {
                  background-image: url('/images/icons/radio-checked.svg');
                }
              }
            }
            .react-select-filters__option {
              display: flex;
              align-items: center;

              &:before {
                content: '';
                display: inline-block;
                width: 15px;
                min-width: 15px;
                height: 15px;
                background-image: url('/images/icons/radio.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 15px;
              }

              &--is-selected {
                color: $black;
                background-color: transparent;

                &:hover,
                &--is-focused {
                  background-color: rgba($brown, 0.3);
                }

                &:before {
                  background-image: url('/images/icons/radio-checked.svg');
                }
              }
            }
          }
        }
      }
    }
  }
}
