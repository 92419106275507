.detailsRow {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 3%;
  padding-top: 5%;

  & img {
    width: 18px;
    display: inline-block;
    padding-top: 2px;
    vertical-align: top;
  }
  .add-margin {
    margin-left: 31px;
  }
  & p {
    font-size: 16px;
    display: inline-block;
    margin-left: 15px;
    width: 85%;
  }
}
.detailsServiceRow {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 3%;
  padding-top: 5%;
  & p {
    font-size: 16px;
    display: inline-block;
    width: 85%;
  }
}
.details-a {
  margin-top: 4%;
  margin-bottom: 5%;
  // float: right;
  & img {
    width: 40px;
  }
}
.details-h {
  font-size: 0.8rem;
  color: #8b959e;
  margin-bottom: 4%;
}
.details-s {
  font-size: 0.8rem;
  color: #8b959e;
}
.detailsText {
  font-size: 16px;
}
.border-bottom {
  border-bottom-color: #8b959e;
  border-bottom-style: solid;
  border-radius: 0px;
  border-width: 0.1px;
}
.details-container {
  padding-right: 0px;
  padding-left: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 60px);
  .fixedheader-wrapper {
    display: flex;
    margin-left: -15px;
    .fixedheader-back {
      margin-top: 51px;
      & img {
        width: 40px;
      }
    }
  }
}

.detailsCol {
  padding-left: 0px;
  padding-top: 4px;
}

.vehicle-price-width {
  @media (max-width: 1205px) and (max-width: 1400px) {
    max-width: fit-content;
    white-space: nowrap;
  }
}

.vehicle-title-limit {
  // text-overflow: ellipsis;
  // overflow: hidden;

  // @media (min-width: 1520px) and (max-width: 1570px) {
  //   width: 12rem !important;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  // }
  @media (min-width: 1520px) and (max-width: 1540px) {
    width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* Above 1540px and up to 1570px */
  @media (min-width: 1541px) and (max-width: 1570px) {
    width: 13rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media (min-width: 1205px) and (max-width: 1500px) {
    width: 15.5rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media (max-width: 1205px) {
    width: 12rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
