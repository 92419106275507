.add-location {
  .form-block {
    max-width: 850px;

    &.wide {
      max-width: 100%;
    }
  }

  .right-side {
    background-color: $white;
    // background: rgba($dark-grey, 0.2);
    border-radius: 6px;
    box-shadow: $default-shadow;
    padding: 30px;
    width: 100%;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .section {
      flex: 1;
    }
  }

  .header-expandable {
    cursor: pointer;
    padding-right: 40px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 10px);
      width: 4.391rem;
      height: 20px;
      background-image: url('/images/icons/caret-down.svg');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &.expanded:before {
      transform: rotate(180deg);
    }
  }

  .remove-section {
    display: block;
    text-align: right;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    font-weight: bold;

    .btn-link {
      font-size: 12px;
      padding-right: 0;
    }
  }
}

.removable-CMS-image {
  max-width: 120%;
  height: auto;
}
