.dropdown {
  .dropdown-menu.action-dropdown-menu {
    border-radius: 2px;
    border: 0.2px solid rgba($dark-grey, 0.25);
    box-shadow: $default-shadow;
    padding: 0;

    .dropdown-item {
      padding: 10px 10px 6px;
      font-size: 12px;
      font-weight: 400;
      color: $black;

      &:not(:last-child) {
        border-bottom: 1px solid $grey;
      }

      &:active {
        background-color: $white;
      }
    }
  }

  .dropdown-menu.action-dropdown-menu-2 {
    border-radius: 2px;
    border: 0.2px solid rgba($dark-grey, 0.25);
    box-shadow: $default-shadow;
    padding: 0;
    margin-left: 100%;

    .dropdown-item {
      padding: 10px 10px 6px;
      font-size: 12px;
      font-weight: 400;
      color: $black;

      &:not(:last-child) {
        border-bottom: 1px solid $grey;
      }

      &:active {
        background-color: $white;
      }
    }
  }

  .dots-toggle {
    padding: 0 3px;
    border: 1px solid transparent;
    border-radius: 2px;
    display: inline-block;

    img {
      width: 17px;
      height: auto;
    }

    &.vertical {
      padding: 5px 8px;

      img {
        width: 4px;
        height: auto;
      }
    }
  }

  &.show {
    .dots-toggle {
      border-color: rgba($dark-grey, 0.25);
      box-shadow: $default-shadow;
    }
  }
}

.titleSelect {
  z-index: unset;
  // z-index: 1050;
}

.bookingStatusDD {
  display: inline-block;
  float: right;
  padding-left: 10px;

  .dropdown {
    line-height: 20px;
    .dropdown-menu.action-dropdown-menu {
      padding: 6px 10px !important;
      min-width: 0rem !important;
      .dropdown-item {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
.cancelDropdown {
  padding: 0px !important;
}

@media (max-width: 768px) {
  .bookingStatusDD {
    .dropdown {
      .dropdown-menu.action-dropdown-menu {
        padding: 6px 10px !important;
        min-width: 0rem !important;
        .dropdown-item {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}

.dropDownMargin {
  margin-right: 2.188em;
}

.grid-dropdown {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}
