.stepper {
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;

  .step {
    position: relative;
    display: table-cell;
    text-align: center;
    padding: 0.5rem;

    .stepper-label {
      color: rgba($black, 0.1);
      text-align: left;
      margin-bottom: 8px;

      &.page-title {
        margin-bottom: 5px;
      }
    }
    .indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      .round-indicator {
        height: 6px;
        width: 6px;
        border: 2px solid rgba($black, 0.1);
        border-radius: 6px;
        margin-right: 10px;
      }
      .info {
        margin-right: 10px;
        font-size: 10px;
        color: rgba($black, 0.1);
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap;
      }
      &:after {
        content: ' ';
        left: 60%;
        width: 85%;
        height: 0.0625rem;
        background-color: rgba($black, 0.1);
        z-index: 1;
      }
    }
    &.active {
      .stepper-label {
        color: $black;
      }
      .indicator {
        .round-indicator {
          border-color: $black;
          background: $black;
        }
        .info {
          color: $black;
        }
        &:after {
          background-color: $black;
        }
      }
    }

    &:last-child {
      .indicator {
        &:after {
          display: none;
        }
      }
    }
  }
}
