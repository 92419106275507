.sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  font-weight: 200;
  background: $black;
  color: $light-grey;
  background-size: cover;
  background-position: center center;
  -webkit-transition: width 0.28s;
  transition: width 0.28s;

  //.sidebar-wrapper{
  //  position: relative;
  //  height: calc(100vh - 75px);
  //  overflow: auto;
  //  width: 260px;
  //  z-index: 4;
  //  padding-bottom: 30px;
  //}
  //
  //.sidebar-background{
  //  position: absolute;
  //  z-index: 1;
  //  height: 100%;
  //  width: 100%;
  //  display: block;
  //  top: 0;
  //  left: 0;
  //  background-size: cover;
  //  background-position: center center;
  //}

  //.logo{
  //  padding: 10px 30px;
  //  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  //  position: relative;
  //  z-index: 4;
  //
  //  p{
  //    float: left;
  //    font-size: 20px;
  //    margin: 10px 10px;
  //    color: $white-color;
  //    line-height: 20px;
  //    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  //  }
  //
  //  a.logo-mini{
  //    float: left;
  //    text-align: center;
  //    width: 30px;
  //    margin-right: 15px;
  //
  //    img{
  //      width: 40px;
  //      margin-left: -3px;
  //      display: block;
  //      margin-top: 2px;
  //    }
  //  }
  //
  //  a.logo-normal{
  //    display: block;
  //  }
  //
  //  .logo-img{
  //    width: 34px;
  //    display: inline-block;
  //    height: 34px;
  //    margin-left: -2px;
  //    margin-top: -2px;
  //    margin-right: 10px;
  //    border-radius: 30px;
  //    text-align: center;
  //  }
  //}
  //
  //.logo-tim{
  //  border-radius: 50%;
  //  border: 1px solid #333;
  //  display: block;
  //  height: 61px;
  //  width: 61px;
  //  float: left;
  //  overflow: hidden;
  //
  //  img{
  //    width: 60px;
  //    height: 60px;
  //  }
  //}
  //
  //.nav{
  //  margin-top: 20px;
  //
  //  li{
  //    > a{
  //      color: #FFFFFF;
  //      margin: 5px 15px;
  //      opacity: .86;
  //      border-radius: 4px;
  //    }
  //
  //    &:hover > a,
  //    &.open > a,
  //    &.open > a:focus,
  //    &.open > a:hover{
  //      background: rgba(255,255,255,0.13);
  //      opacity: 1;
  //    }
  //
  //    &.active > a{
  //      color: #FFFFFF;
  //      opacity: 1;
  //      background: rgba(255,255,255,0.23);
  //
  //    }
  //  }
  //
  //  p{
  //    margin: 0;
  //    line-height: 30px;
  //    font-size: 12px;
  //    font-weight: 600;
  //    text-transform: uppercase;
  //  }
  //
  //  .caret{
  //    top: 24px;
  //    position: absolute;
  //    right: 15px;
  //  }
  //
  //  i{
  //    font-size: 28px;
  //    float: left;
  //    margin-right: 15px;
  //    line-height: 30px;
  //    width: 30px;
  //    text-align: center;
  //  }
  //}
}

.sidebar-container-partner {
  width: calc(100vw - 85%);
  min-width: 13rem;
  max-width: 15rem;

  .sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    font-size: 14px;
    font-weight: 400;
    color: $dark-grey;
    padding: 0;
    margin-bottom: 19px;
    display: flex;
    align-items: center;

    .icon {
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: 10px;
      display: block;

      &-active {
        display: none;
      }
    }

    &:hover,
    &.active {
      color: $white;
      font-weight: 500;
      .icon {
        display: none;

        &-active {
          display: block;
        }
      }
    }

    span {
      padding-top: 15px;
      padding-left: 5px;
    }
  }

  &.sidebar-white {
    background: $white;
    color: $black;

    .nav-link {
      color: $dark-grey;
      font-weight: 600;
      font-size: 16px;

      &:hover,
      &.active {
        color: $black;
        font-weight: 600;
      }
    }
  }

  .partner-client-logo-wrap {
    position: absolute;
    bottom: 0;
    padding-bottom: 4rem;
    padding-left: 3rem;

    img {
      width: 110px;
    }
  }
}

.page-wrap-partner {
  width: calc(100% - 10rem) !important;
  .page-with-panel {
    @media (min-width: 768px) {
      width: calc(100% - 380px);
    }

    main {
      padding-right: 20px !important;
    }
  }

  .scrollable-page-content {
    height: 100vh;
    overflow: auto;
  }

  .scrollable-page-content.light-theme {
    height: calc(100vh - 60px);
  }

  header {
    background-color: $grey;
    padding: 40px 40px 40px 40px;
    position: relative;
    margin: 15px 17px 0;

    .partner-page-title {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 0;
      height: 40px;
    }

    @include media-breakpoint-down(md) {
      padding: 30px;
      padding-left: 30px;
      margin: 0;
    }
  }

  main {
    padding: 75px 10% 60px 10%;
  }
  .grey-theme {
    min-height: 100vh;
    background-color: rgba($dark-grey, 0.1);
    header {
      background-color: transparent;
      font-size: 40px;
      padding-bottom: 0;
      padding-left: 60px;
      margin-left: 0px;

      @include media-breakpoint-down(lg) {
        padding-left: 40px;
      }

      @include media-breakpoint-down(md) {
        padding-left: 30px;
      }
    }

    main {
      padding-top: 15px;
      padding-left: 60px;

      &.hide-sidebar {
        &.no-left-padding {
          padding-left: 40px;
        }
        @media (min-width: 765px) and (max-width: 1330px) {
          padding-left: 11vw;
        }
      }

      @include media-breakpoint-down(lg) {
        padding-left: 40px;
      }

      @include media-breakpoint-down(md) {
        padding-left: 30px;
      }
    }

    &.nested-layout {
      &.reset-password-layout {
        h4 {
          white-space: pre-wrap;
          line-height: 32px;
        }
        p.confirm-message {
          font-size: 20px;
        }
        header {
          padding: 95px 90px 100px 260px;
          .back-btn {
            top: calc(50% - 38px);
          }
        }
        .success-msg-verification {
          height: calc(100vh - 140px);
          color: rgba($dark-grey, 0.1);
          z-index: 10;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          top: 0;
          left: 0;
          background: rgba($dark-grey, 0.1);
          overflow-y: auto;

          img {
            width: 80px;
            height: 80px;
            margin-bottom: 15px;
          }

          .success-msg-header {
            font-size: 16px;
            font-weight: 400;
          }

          .success-msg-description {
            font-size: 16px;
            font-weight: 400;
          }

          .content-wrap {
            display: flex;
            max-width: 500px;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}

.sidebar-container,
body > .navbar-collapse {
  //.logo{
  //  padding: 10px 30px;
  //  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  //
  //  p{
  //    float: left;
  //    font-size: 20px;
  //    margin: 10px 10px;
  //    color: $white-color;
  //    line-height: 20px;
  //    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  //  }
  //
  //  .simple-text{
  //    text-transform: uppercase;
  //    padding: $padding-small-vertical $padding-zero;
  //    display: block;
  //    font-size: $font-size-large;
  //    color: $white-color;
  //    font-weight: $font-weight-normal;
  //    line-height: 30px;
  //  }
  //}
  //
  //.logo-tim{
  //  border-radius: 50%;
  //  border: 1px solid #333;
  //  display: block;
  //  height: 61px;
  //  width: 61px;
  //  float: left;
  //  overflow: hidden;
  //
  //  img{
  //    width: 60px;
  //    height: 60px;
  //  }
  //}

  &:after,
  &:before {
    //display: block;
    //content: "";
    //position: absolute;
    //width: 100%;
    //height: 100%;
    //top: 0;
    //left: 0;
    //z-index: 2;
  }

  &:before {
    //opacity: .33;
    //background: #000000;
  }

  &:after {
    //@include icon-gradient($black-color-top, $black-color-bottom);
    //z-index: 3;
  }
}

.page-wrap-dark {
  // width: $sidebar-width-dark;
  width: $sidebar-width-without-menu;
  // overflow: hidden;
}

.page-wrap-light {
  width: $sidebar-width-without-menu;
}

.pagewrap-with-menu {
  @media (min-width: 768px) {
    width: $sidebar-width-with-menu;
  }
}

.page-wrap,
.page-wrap-partner {
  position: relative;
  float: right;

  > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px);
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
    padding: 0;
    .navbar-brand {
      padding: 0;
      margin: 0;
      display: block;
      width: 100%;
    }
  }
  .navbar-partner {
    margin-bottom: 0;
    padding: 0;
    .navbar-brand {
      padding: 0;
      margin: 0;
      display: block;
      width: 100%;
    }
  }
}

.sidebar-container,
.page-wrap {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.sidebar-minimized {
  width: 115px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s;

  .client-info .company-logo-text {
    margin-left: -20px;
  }
}
.sidebar-minimized-admin {
  width: 100px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s;

  .client-info .company-logo-text {
    margin-left: -20px;
  }
}

.sidebar-expanded {
  width: 280px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
}

.sidebar-expanded-admin {
  width: 320px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
}

.admin-sideBar-icons {
  margin-right: 10px;
  margin-top: 1.5rem;
}
.admin-sideBar-icons-withIndecator {
  margin-right: 10px;
  margin-top: 1.5rem;
}
.admin-sideBar-group {
  margin-top: 1.5rem;
}

.admin-notes-border {
  // box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  border-color: rgba(139, 149, 158, 0.25) !important;
  // text-align: right !important;
}

.nav-link-header {
  font-size: 14px;
  font-weight: 400;
  color: $white !important;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}
.operationsTab {
  background: url('/images/icons/icon-operations.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.networkTab {
  background: url('/images/icons/icon-network.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.fleetTab {
  background: url('/images/icons/icon-fleet.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.ratesTab {
  background: url('/images/icons/icon-rates.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.financeTab {
  background: url('/images/icons/icon-finance.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.accountTab {
  background: url('/images/icons/icon-account.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.automationTab {
  background: url('/images/icons/icon-automation.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}

.payoutTab {
  background: url('/images/icons/icon-payout.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
.analyticsTab {
  background: url('/images/icons/analytics.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}

.rotateArrow {
  transform: rotate(90deg);
}

.rolzoLocationsTab {
  background: url('/images/icons/icon-locations.png');
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px;
}
