.chargeTab {
  font-size: 15px !important;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #303030;
  opacity: 1;
  margin-bottom: -2px;
  padding-bottom: 0px;
  font-weight: 600;
  &:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #303030;
    opacity: 1;
    margin-bottom: -2px;
    padding-bottom: 0px;
    font-weight: 600;
  }
  &:focus {
    box-shadow: none;
  }
}
.activeChargeTab {
  font-size: 15px !important;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #303030;
  opacity: 1;
  margin-bottom: -2px;
  padding-bottom: 0px;
  font-weight: 600;
  border-bottom: 1.5px solid $black;
  &:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #303030;
    opacity: 1;
    margin-bottom: -2px;
    padding-bottom: 0px;
    font-weight: 600;
    border-bottom: 1.5px solid $black;
  }
  &:focus {
    box-shadow: none;
  }
}
.chargeTargetFields {
  padding-left: 0.9375rem;
  padding-bottom: 1rem;
}
.chargeCheckBox {
  padding-left: 1.9rem !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-left: 6.5rem;
}
.chargeCheckBox label {
  color: $dark-grey !important;
  padding-top: 0.2rem !important;
  line-height: 12px;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: pre;
}

.buying-amount {
  // margin-left: 11rem;
  // padding-right: 0.75rem;
  .form-label {
    white-space: nowrap;
  }
}
.buying-total-discount {
  // padding-right: 0rem;
  // padding-left: 1.8rem;
  padding-inline: 15px;
}
.buying-total-commission {
  padding-right: 0.5rem;
  padding-left: 1.2rem;
}

.partnerFields {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // padding-left: 0.9375rem;
  margin-inline: 0;
}
.partnerFields-commission {
  width: 100%;
  padding-left: 0.9375rem;
}

.chargesBackGround {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: $white !important;
  margin-bottom: 1rem !important;

  padding-right: 2.1875rem !important;
  padding-left: 0.9375rem !important;
}

.driver-service-additionlCharges {
  background-color: rgba($dark-grey, 0.1);
  padding: 1em 1em;
  font-size: 15px;
  span.title {
    color: $dark-grey;
    max-width: 75%;
  }
}
