.success-msg-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  color: $white;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  background: $white;
  overflow-y: auto;
  flex-wrap: wrap;

  img {
    display: block;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 0 auto 35px auto;
  }

  .success-msg-header {
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
    //margin-bottom: 25px;
  }

  .success-msg-description {
    font-size: 15px;
    color: $dark-grey-2;
    text-align: center;
    white-space: pre-wrap;
    line-height: 26px;
  }

  .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }

  .content-wrap {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center;
  }

  .content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.success-msg-verification {
  height: 100vh;
  color: $white;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  background: $white;
  overflow-y: auto;

  img {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin: 0 auto 15px auto;
  }

  .success-msg-header {
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
    margin-bottom: 25px;
  }

  .success-msg-description {
    font-size: 15px;
    color: $dark-grey-2;
    text-align: center;
    white-space: pre-wrap;
    line-height: 10px;
  }

  .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }

  .content-wrap {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center;
  }
}
.success-msg-quote {
  color: $white;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  margin-top: 5%;
  background: $white;

  img {
    display: block;
    width: 150px;
    height: 110px;
    object-fit: cover;
    margin: 0 auto 15px auto;
    margin-bottom: 0;
  }

  .success-msg-header {
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
  }

  .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }

  .content-wrap {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center;
  }
}

.door-to-door-div {
  color: #0c0c0c;
  margin-top: 2rem;
  background-color: rgba($brown, 0.1);
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  border-radius: 4px;

  span:first-child {
    font-size: 20px;
    text-align: center;
  }

  .door-to-door-cards {
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .door-to-door-card {
    background: white;
    padding: 1.5rem 2rem;
    border: 1px solid rgba(139, 149, 158, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border-radius: 2px;

    img {
      width: 30px !important;
      height: 30px !important;
      margin: 0 !important;
    }

    span {
      display: block;
      font-weight: bold;
      margin-top: 15px;
    }

    span:last-of-type {
      display: block;
      font-weight: normal !important;
      color: $dark-grey-2;
      margin-top: 15px;
      text-align: center;
    }

    .book-btn {
      width: 100%;
      margin-top: 5px;

      button {
        width: inherit !important;
      }
    }
  }
}
