$font-family-base: 'AvenirNext', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$submit-btn-font: 'BentonSans-Regualar';
$benefits-btn-font: 'BentonSans-Regualar';
$promoCode-btn-front: 'BentonSans-Regualar';

$black: #0c0c0c;
$dark: #8b959e;
$brown: #a79779;
$light: #fafafa;

//$dark: #4a4a4a;
$dark-2: #303030;
$dark-3: #323c47;
$dark-4: #213747;
//$light: #f2f2f2;
$light-2: #fbfbfb;
$light-3: #fafafa;
$light-4: #fdfdfd;
$light-grey: #ebebeb;
$light-grey-2: #e9eff4;
$grey: #e6e6e6;
$dark-grey: #8b959e;
$dark-grey-2: #636d77;
//$brown: #a79779;
$brown-active: #96866a;
$dark-brown: #a5977c;
$white: #ffffff;
$green: #417505;
$red: #d0021b;
$aquamarine: #50e3c2;
$dark-green: #016360;
$light-green: #5fd837;
$blue: #1e2b8e;
$dark-blue: #3140b0;
$yellow: #fabd05;
$orange: #ff9500;
$purple: #800080;
$red-2: #ff6666;
$purple-2: #635bff;

$theme-colors: (
  'brown': $brown,
  'dark-grey': $dark-grey,
);

$body-color: $black;

$default-shadow: 0px 2px 4px 0px rgba(#000000, 0.1);
$card-shadow: 0px 10px 40px 0px rgba(#000000, 0.1);
$form-control-border: 0.5px solid transparent;

$primary: #007bff;
$secondary: #6c757d;
$success: #417505;
$info: #17a2b8;
$warning: #f5a623;
$danger: #d0021b;

$link-disabled-color: #666666 !default;

//== Components
//

$padding-base-vertical: 8px !default;
$padding-base-horizontal: 16px !default;

$padding-round-vertical: 9px !default;
$padding-round-horizontal: 18px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 18px !default;

$padding-large-vertical: 14px !default;
$padding-large-horizontal: 30px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;

$padding-zero: 0px !default;

$margin-bottom: 0 0 10px 0 !default;
$border-radius-small: 3px !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-extreme: 10px !default;

$border-radius-large-top: $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius: 30px !default;

$height-base: 40px !default;

//$font-size-base:               14px !default;
//$font-size-small:              12px !default;
//$font-size-medium:             16px !default;
//$font-size-large:              18px !default;
//$font-size-large-navbar:       20px !default;
//
//$font-size-h1:                 52px !default;
//$font-size-h2:                 36px !default;
//$font-size-h3:                 28px !default;
//$font-size-h4:                 22px !default;
//$font-size-h5:                 16px !default;
//$font-size-h6:                 14px !default;
//$font-paragraph:               16px !default;
//$font-size-navbar:             16px !default;
//$font-size-small:              12px !default;
//
//$font-weight-light:          300 !default;
//$font-weight-normal:         400 !default;
//$font-weight-semi:           500 !default;
//$font-weight-bold:           600 !default;
//
//$line-height-general:          1.5 !default;
//$line-height:                 20px !default;
//$line-height-lg:              54px !default;
//
$sidebar-width-with-menu: calc(100% - 280px) !important;
$sidebar-width-without-menu: calc(100% - 100px) !default;
$sidebar-width-dark: calc(100% - 280px) !default;

$border-radius-top: 10px 10px 0 0 !default;
$border-radius-bottom: 0 0 10px 10px !default;

$dropdown-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);

$general-transition-time: 300ms !default;

$slow-transition-time: 370ms !default;
$dropdown-coordinates: 29px -50px !default;

$fast-transition-time: 150ms !default;

$ultra-fast-transition-time: 100ms !default;

$select-coordinates: 50% -40px !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;
$transition-ease-in: ease-in !default;
$transition-ease-out: ease-out !default;

$navbar-padding-a: 10px 15px;
$navbar-margin-a: 10px 3px;

$padding-social-a: 10px 5px;

$navbar-margin-a-btn: 15px 3px;
$navbar-margin-a-btn-round: 16px 3px;

$navbar-padding-a-icons: 6px 15px;
$navbar-margin-a-icons: 6px 3px;

$navbar-padding-brand: 15px 15px;
$navbar-margin-brand: 5px 0px;

$navbar-margin-brand-icons: 12px auto;

$navbar-margin-btn: 15px 3px;

$height-icon: 64px !default;
$width-icon: 64px !default;
$padding-icon: 12px !default;
$border-radius-icon: 15px !default;

$size-icon: 64px;
$size-icon-sm: 32px;

$height-icon-sm: 32px;
$width-icon-sm: 32px;
$padding-icon-sm: 4px;
$border-radius-icon-sm: 7px;

$height-icon-message: 40px;
$width-icon-message: 40px;

$height-icon-message-sm: 20px;
$width-icon-message-sm: 20px;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;
