.toggleSwitch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  &.mt5px {
    margin-top: 5px;
  }
  &.ml30 {
    margin-left: 30px;
  }
  a {
    padding-top: 3px;
    color: #8b959e !important;
  }
  label {
    position: relative;
    display: inline-block;
    margin-top: 4px;
    width: 30px;
    height: 15px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid;
    border-color: #8b959e;
    transition: 0.3s;
    border-radius: 30px;
  }
  span:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: -2px;
    bottom: -2px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
    border-color: grey;
    border: 2px solid;
  }

  .active {
    color: black !important;
  }
  input:checked + span {
    background-color: black;
    border-color: black;
  }

  input:checked + span:before {
    transform: translateX(15px);
    border-color: black;

    // left: -12px;
  }
}
