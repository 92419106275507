.data-table-wrap {
  margin-bottom: 30px;
}
.data-table-admin {
  td,
  th {
    // padding: 0px 20px 0px !important;
    padding: 5px !important;
  }
}
.quotes-list {
  .data-table {
    max-width: none;
  }
}

.data-table {
  // max-width: 1000px;
  border-spacing: 0 8px;
  border-collapse: separate;
  line-height: 20px;
  margin-bottom: 0;

  &.wide {
    max-width: 970px;
  }
  .border-label {
    border: 1px solid rgba($black, 0.6);
    padding: 5px 12px;
    border-radius: 2px;
    font-size: 13px;
  }
  th,
  td {
    // padding: 5px;
    padding: 16px 20px 20px;
    font-size: 14px;
    vertical-align: middle;
  }
  thead {
    th {
      font-weight: 500;
      // color: $dark;
      color: $black;
      //text-transform: uppercase;
      border-bottom: 1px solid $dark;
      border-top: 0;
      white-space: nowrap;
      font-size: 15px;
    }
  }

  td {
    font-weight: 400;
    border-top: 0;
    background-color: $light-3;
    border-top: 0.5px solid transparent;
    border-bottom: 0.5px solid transparent;
    white-space: nowrap;
    &:first-child {
      //border-top-left-radius: 2px;
      //border-bottom-left-radius: 2px;
      border-left: 0.5px solid rgba($dark, 0.5);
    }

    &:last-child {
      //border-top-right-radius: 2px;
      //border-bottom-right-radius: 2px;
      border-right: 0.5px solid rgba($dark, 0.5);
    }
  }

  tbody {
    &:before {
      content: '';
      display: block;
      height: 5px;
    }
    tr {
      td {
        border-color: rgba($dark, 0.5);
      }
      &:hover {
        td {
          // border-color: $black;
        }
      }
    }
  }
}

.light-theme-table {
  .data-table {
    tr {
      box-shadow: $default-shadow;
      td {
        background-color: $light-2;
        border-color: transparent;
        white-space: nowrap;

        &:last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }

      &:hover {
        td {
          border-color: transparent;
        }
      }
    }

    thead {
      tr {
        box-shadow: none;
      }
      th {
        background: transparent;
        font-weight: 500;
        color: $black;
        border-bottom: 1px solid rgba($dark-grey, 0.3);
        border-top: 0;
        font-size: 14px;
      }
    }
    tbody {
      tr {
        &:hover {
          box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &.no-action {
    tbody tr {
      &:hover {
        box-shadow: $default-shadow;
      }
    }
  }

  .minWidth8rem {
    min-width: 8rem;
  }
  .minWith9rem {
    min-width: 9rem;
  }
  .minWith12rem {
    min-width: 12rem;
  }
  .maxWidth10rem {
    max-width: 10rem;
    white-space: pre;
  }
  .maxWidth9rem {
    max-width: 9rem;
  }
  .maxWidth8rem {
    max-width: 8rem;
  }
}
.light-theme-table-admin-bookings {
  .data-table {
    tr {
      box-shadow: $default-shadow;
      td {
        background-color: $light-3;
        border-color: transparent;

        padding: 5px 5px 5px;
        font-size: 14px;
        vertical-align: middle;
        max-width: 10.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        &:last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
      td.status {
        max-width: 12rem;
      }
      &:hover {
        td {
          border-color: transparent;
        }
      }
    }

    thead {
      tr {
        box-shadow: none;
      }
      th {
        background: transparent;
        font-weight: 900;
        color: $black;
        border-bottom: 1px solid rgba($dark-grey, 0.3);
        border-top: 0;
        font-size: 12px;
        padding: 5px 5px 5px;
      }
    }
    tbody {
      tr {
        &:hover {
          box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &.no-action {
    tbody tr {
      &:hover {
        box-shadow: $default-shadow;
      }
    }
  }

  .minWidth8rem {
    min-width: 8rem;
  }
  .minWith9rem {
    min-width: 9rem;
  }
  .minWith12rem {
    min-width: 12rem;
  }
  .maxWidth10rem {
    max-width: 10rem;
    white-space: pre;
  }
  .maxWidth9rem {
    max-width: 9rem;
  }
  .maxWidth8rem {
    max-width: 8rem;
  }
}

.light-theme-table-partner {
  min-height: 400px;

  .data-table {
    border: 1.5px solid rgba($dark-grey, 0.2);
    background: $light;
    padding: 0rem 1.5rem 1rem 1.5rem;
    max-width: unset;
    border-radius: 10px;
    tr {
      td {
        background-color: rgba($dark-grey, 0.05);
        border-color: transparent;
        padding: 16px 12px 16px;

        &:last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
    }

    thead {
      tr {
        box-shadow: none;
      }
      th {
        background: transparent;
        font-weight: 600;
        color: #000000;
        border-bottom: 0;
        border-top: 0;
        font-size: 13px;
        padding: 16px 20px 0px;
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: rgba($dark-grey, 0.2);
          cursor: pointer;
          td {
            border-color: transparent;
            box-shadow: none;
          }
        }
      }
    }
  }

  .minWidth8rem {
    min-width: 8rem;
  }
  .minWith12rem {
    min-width: 12rem;
  }
}

@media (max-width: 992px) {
  .light-theme-table-partner {
    min-height: 400px;

    .data-table {
      border: 1.5px solid rgba($dark-grey, 0.2);
      background: $light;
      padding: 0rem 1.5rem 1rem 1.5rem;
      max-width: unset;
      border-radius: 10px;
      tr {
        td {
          background-color: rgba($dark-grey, 0.1);
          border-color: transparent;
          padding: 16px 12px 16px;

          &:last-child {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          &:first-child {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
        }

        &:hover {
          td {
            border-color: transparent;
            box-shadow: none;
          }
        }
      }

      thead {
        tr {
          box-shadow: none;
        }
        th {
          background: transparent;
          font-weight: 600;
          color: #000000;
          border-bottom: 0;
          border-top: 0;
          font-size: 13px;
          padding: 16px 20px 0px;
        }
      }
    }

    .minWidth8rem {
      min-width: 8rem;
    }
    .minWith12rem {
      min-width: 12rem;
    }
  }
}

.light-theme-table-dispatch {
  .data-table {
    tr {
      box-shadow: $default-shadow;
      td {
        background-color: $light-2;
        border-color: transparent;

        &:last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }

      &:hover {
        td {
          border-color: transparent;
        }
      }
    }

    thead {
      tr {
        box-shadow: none;
      }
      th {
        background: transparent;
        font-weight: 500;
        color: $black;
        border-bottom: 1px solid rgba($dark-grey, 0.3);
        border-top: 0;
        font-size: 14px;
      }
    }
    tbody {
      &:before {
        display: none;
      }
      tr {
        &:hover {
          box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &.no-action {
    tbody tr {
      &:hover {
        box-shadow: $default-shadow;
      }
    }
  }
}

.invoices-table {
  .data-table {
    th,
    td {
      // padding-left: 20px !important;
      // padding-right: 5px !important;
      padding-inline: 20px !important;
    }
  }
}
.service-label {
  //border: 1px solid rgba($black, 1);
  //border: 1px solid rgba($dark-grey, 0.65);
  border-radius: 2px;
  color: $black;
  //color: rgba($dark-grey, 0.65);
  padding: 2px 4px;
  width: fit-content;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  &.label-black {
    color: $dark-grey;
    border-color: $dark-grey;
  }
}

.monitoring-Table-td {
  padding: 5px 5px 5px !important;
  font-size: 12px !important;
  vertical-align: middle !important;
}
.tests-table-td {
  font-size: 14px !important;
  vertical-align: middle !important;
}
.td-width {
  width: 20% !important;
}

.light-theme-table-admin-payout {
  .data-table {
    tr {
      box-shadow: $default-shadow;
      td {
        background-color: $light-2;
        border-color: transparent;

        padding: 5px 5px 5px;
        font-size: 14px;
        vertical-align: middle;
        max-width: 10.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        &:last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
      &.expanded {
        box-shadow: none !important;
        tbody {
          tr {
            &:hover {
              box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      td.status {
        max-width: 12rem;
      }
      &:hover {
        td {
          border-color: transparent;
        }
      }
    }

    thead {
      tr {
        box-shadow: none;
      }
      th {
        background: transparent;
        font-weight: 900;
        color: $black;
        border-bottom: 1px solid rgba($dark-grey, 0.3);
        border-top: 0;
        font-size: 12px;
        padding: 5px 5px 5px;
      }
    }
    tbody {
      tr {
        &:hover {
          box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &.no-action {
    tbody tr {
      &:hover {
        box-shadow: $default-shadow;
      }
    }
  }
}

.payout-dropDown {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 5px 5px;
  margin-right: 5px;
  border-bottom: 1px solid rgba($dark-grey, 0.3);
  border-spacing: 5px !important;

  span {
    font-size: 14px !important;
    font-weight: 400;
    color: $black;
    padding-top: 5px;
    padding-bottom: 5px;
    // vertical-align: middle;
    // padding-top: 5px !important;
    // padding-bottom: 5px !important;
  }
  &:first-child {
    border-top: 1px solid rgba($dark-grey, 0.3);
  }
  &:last-child {
    margin-bottom: 1rem;
    border-bottom: none;
  }

  .status-paid {
    margin-left: 0.3rem;
    font-size: 12px !important;
    color: $success;
    padding: 0px;
  }
  .status-unpaid {
    font-size: 13px;
    color: $dark;
  }
}

.payout-details {
  display: flex;
  justify-content: space-between;
  padding: 1em 5px 1em 0px;
  margin-right: 5px;
  border-bottom: 1px solid rgba($dark-grey, 0.3);
  border-spacing: 5px !important;

  span {
    font-size: 14px !important;
    font-weight: 400;
    color: $black;
    vertical-align: middle;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 2;
}
.fixed-column-bg-white {
  position: sticky;
  background-color: white !important;
  left: 0;
  z-index: 2;
}
.analyticsSalesTable {
  .analyticsBorderRight {
    border-right: 1px solid rgba(139, 149, 158, 0.3) !important;
  }
  border-spacing: 0 1px;
  th {
    border-top: 0;
    min-width: 60px;
  }
  tbody {
    &:before {
      display: none;
    }
    tr {
      td {
        // border-color: rgba($dark, 0.5);
      }
      &:hover {
        td {
          // border-color: $black;
        }
      }
    }
  }
}

.pagination-parent {
  margin-top: 40px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.pagination-info-container {
  font-size: 12px;
  padding-inline: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
  .pagination-options-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid rgba($dark-grey, 0.3);
    width: 105px;
    height: fit-content;
    border-radius: 0 0 2px 2px;
    .pagination-option {
      width: 100%;
      font-size: 13px;
      padding: 10px;
      &.selected,
      &:hover {
        background-color: #e1e4e5;
      }
    }
  }
}
.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    height: 24px;
    width: 24px;
    text-align: center;
    color: $black;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    line-height: 1.43;
    font-size: 12px;
    justify-content: center;

    &.width-30px {
      width: 30px;
    }
    &.dots:hover {
      background-color: transparent;
      cursor: default;
      color: black;
    }
    &.arrow:hover {
      background-color: transparent;
      cursor: pointer;
    }
    &:hover {
      background-color: $black;
      color: $white;
      cursor: pointer;
    }

    &.selected {
      background-color: $black;
      color: $white;
    }

    &.arrow-right {
      margin-left: 12px;
    }
    &.arrow-left {
      margin-right: 12px;
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.consortiums-input,
.host-agencies-input {
  width: 370px;
  @include media-breakpoint-down(sm) {
    width: 270px;
  }
}
.max-width333px {
  max-width: 333px;
}
.max-width260px {
  max-width: 260px;
}
.width190px {
  width: 190px;
  min-width: 190px;
}
.width140px {
  width: 140px;
  min-width: 140px;
}
.width160px {
  width: 160px;
  min-width: 160px;
}
.width122px {
  width: 122px;
  min-width: 122px;
}

.mw-270px {
  max-width: 270px;
  width: 270px;
}
.max-w-100px {
  max-width: 100px;
}
.max-width-120px {
  max-width: 120px;
}
.max-w-158px {
  max-width: 158px;
}
.white-space-break {
  white-space: break-spaces !important;
}
.automation-table {
  td {
    padding: 0.1rem;
    border: 1px solid rgba(139, 149, 158, 0.5);
  }
  th {
    padding-bottom: 0.25rem;
  }
}
