.no-data-wrap {
  min-height: 50vh;
  margin-top: 60px;
  .no-data-img-wrap {
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid $dark-grey;
    display: flex;
    justify-content: center;
    img {
      object-fit: contain;
    }
  }
  .left-space {
    img {
      object-fit: contain;
      margin-left: 7px;
    }
  }
  .no-data-header {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
  }

  .no-data-description {
    font-size: 15px;
    color: $dark-grey;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  .no-data-link {
    font-size: 16px;
  }

  &.no-notifications {
    margin-top: 30vh;
  }
}

.no-data-partner {
  margin-top: 15vh !important;
}

.team-bookings-table {
  max-width: 1000px !important;
}

@media (max-width: 768px) {
  .no-data-wrap.no-notifications {
    margin-top: 25vh;
  }
}

.no-payouts {
  margin-top: 9rem;
}
