.image-list {
  h5.image-list-header {
    line-height: 12px;
    font-size: 10px;
    font-weight: 500;
    color: $dark-2;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .image-list-row {
    margin-bottom: 90px;
  }

  .col.image-list-col {
    max-width: 200px;
    img:not(:first-child) {
      margin: 0 15px;
    }

    img:first-child {
      margin-right: 15px;
    }
  }
}

.btn.remove-image-btn {
  display: block;
  font-size: 10px;
  line-height: 14px;
  &:hover {
    text-decoration: none;
  }
}
