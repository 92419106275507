.event-supportbar-btn-wrap {
  margin: 20px 0px 15px 15px;
  cursor: pointer;
}

.event-main-padding {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
  width: 100%;
  height: 100%;
}

.event-settings-container {
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: calc(100% - 10vw);
  .event-card {
    border-radius: 10px;
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $white;
    padding: 1.5em 1.5em;
    font-size: 15px;

    .event-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
    }
  }
  .event-card-title {
    margin-top: 2em !important;
    border-radius: 10px 10px 0px 0px !important;
  }
  .event-card-body {
    margin-top: 0em;
    border-top: none;
    border-radius: 0px 0px 10px 10px !important;
  }
  .event-card-footer {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em;
  }
  .event-edit-wrap {
    // padding: 1em;
    padding: 1.5em 1.5em;

    .event-edit-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      input {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.1) !important;
        font-size: 15px !important;

        &:hover,
        &:focus,
        &:active {
          border: 0.5px solid #000000 !important;
        }
      }
      // input#startsAt {
      //   background-color: $white !important;
      // }
      // input#endsAt {
      //   background-color: $white !important;
      // }
      .event-checkbox-background {
        padding: 1rem 0.5rem 0.5rem 0.8rem;
        background-color: $light-2;
      }
      .event-service-label {
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #8b959e;
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.5rem;
      }
      .custom-control-label {
        max-width: fit-content;
        flex-grow: 1;
        padding-top: 0.2rem;
        padding-left: 0.3rem;
        cursor: pointer;
        text-transform: none;
        letter-spacing: initial;
        font-weight: 400;
        font-size: 14px !important;
        color: #0c0c0c;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
      }
    }
  }
}

.event-card-container {
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: calc(100% - 5vw);
  .event-card {
    border-radius: 10px;
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $white;
    padding: 1.5em 1.5em;
    font-size: 15px;

    .event-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
    }
  }
  .event-card-title {
    margin-top: 2em !important;
    border-radius: 10px 10px 0px 0px !important;
  }
  .event-card-body {
    margin-top: 0em;
    border-top: none;
    border-radius: 0px 0px 10px 10px !important;
  }
  .event-card-footer {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em;
  }
  .event-edit-wrap {
    // padding: 1em;
    padding: 1.5em 1.5em;

    .event-edit-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      input {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.1) !important;
        font-size: 15px !important;

        &:hover,
        &:focus,
        &:active {
          border: 0.5px solid #000000 !important;
        }
      }
      // input#startsAt {
      //   background-color: $white !important;
      // }
      // input#endsAt {
      //   background-color: $white !important;
      // }
      .event-checkbox-background {
        padding: 1rem 0.5rem 0.5rem 0.8rem;
        background-color: $light-2;
      }
      .event-service-label {
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #8b959e;
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.5rem;
      }
      .custom-control-label {
        max-width: fit-content;
        flex-grow: 1;
        padding-top: 0.2rem;
        padding-left: 0.3rem;
        cursor: pointer;
        text-transform: none;
        letter-spacing: initial;
        font-weight: 400;
        font-size: 14px !important;
        color: #0c0c0c;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
      }

      .value-fields {
        padding: 1rem 0.5rem 0.5rem 0.8rem;
        background-color: $light-2;
        margin-right: 1rem;
      }
    }

    .event-notes {
      display: block;
      min-height: 100px;
      border: 0.5px solid transparent;
      background-color: $light-2;
      border-radius: 2px;
      box-shadow: $default-shadow;
      padding: 0.375rem 0.75rem;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .no-data-wrap {
    min-height: 35vh !important;
  }
}

.event-newForm-container {
  .event-checkbox-background {
    padding: 1rem 0.5rem 0.5rem 0.8rem;
    background-color: $light-2;
    box-shadow: $default-shadow;
  }

  .event-edit-wrap {
    // padding: 1em;
    padding: 1.5em 1.5em;

    .event-edit-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      input {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.1) !important;
        font-size: 15px !important;

        &:hover,
        &:focus,
        &:active {
          border: 0.5px solid #000000 !important;
        }
      }
      .event-service-label {
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #8b959e;
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.5rem;
      }
      .custom-control-label {
        max-width: fit-content;
        flex-grow: 1;
        padding-top: 0.2rem;
        padding-left: 0.3rem;
        cursor: pointer;
        text-transform: none;
        letter-spacing: initial;
        font-weight: 400;
        font-size: 14px !important;
        color: #0c0c0c;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
      }
    }
  }
  .new-event-form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .event-service-label {
      line-height: 12px;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #8b959e;
      text-transform: uppercase;
      display: block;
      margin-bottom: 0.5rem;
    }
    .custom-control-label {
      max-width: fit-content;
      flex-grow: 1;
      padding-top: 0.2rem;
      padding-left: 0.3rem;
      cursor: pointer;
      text-transform: none;
      letter-spacing: initial;
      font-weight: 400;
      font-size: 14px !important;
      color: #0c0c0c;
      position: relative;
      margin-bottom: 0;
      vertical-align: top;
    }
  }
  .event-form-footer {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em;
    padding-right: 1.5em;
  }
}

@media (min-width: 768px) {
  .app-content {
    .event-supportbar-gap {
      display: flex;
      width: auto !important;

      // @include media-breakpoint-down(lg) {
      //   padding-left: 30px;
      // }

      // @include media-breakpoint-down(md) {
      //   padding-left: 20px;
      // }
    }

    .supportbar-wrap {
      .custom-tabs-nav {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        background: rgba($dark-grey, 0.15);
        padding: 7px 15px;
        border-radius: 4px;
        font-size: 13px;
        margin-bottom: 0px !important;
        margin-top: 0.45em;
        white-space: nowrap;

        margin-bottom: 0em !important;
        border: none !important;

        .nav-link {
          background-color: transparent;
          color: rgba($dark-grey, 0.5);
          font-weight: 500;
          padding: 9px 18px;
          border-radius: 3px;
          padding: 7px 7px 14px 7px !important;
          height: fit-content !important;
          margin-top: 0.5em !important;
          z-index: 10;

          &:hover {
            color: rgba($dark-grey, 0.7);
          }

          &.active {
            background-color: $white;
            color: $dark-3;
            box-shadow: $default-shadow;
          }
        }

        &.light-tabs {
          border-bottom: none;
          border-radius: 0;
          width: 100%;
          max-width: 1000px;
          background: transparent;
          font-size: 15px;
          padding: 0;
          .nav-link {
            background: transparent;
            border-radius: 0;
            font-size: 14px;
            font-weight: 600;
            color: #8b959e;
            opacity: 0.55;
            padding: 7px 7px 0px 7px;
            border: none;

            &.active {
              background: transparent;
              border: none;
              border-bottom: 2px solid $black;
              box-shadow: none;
              color: $dark-2;
              opacity: 1;
              margin-bottom: -2px;
              font-weight: 600;
            }

            &:focus {
              box-shadow: none;
            }

            //Tabs sizes
            &:not(:last-child) {
              margin-right: 25px;
            }
            @media (max-width: 870px) {
              &:not(:last-child) {
                margin-right: 15px;
              }
            }
            @media (max-width: 810px) {
              &:not(:last-child) {
                margin-right: 10px;
              }
            }
            @media (max-width: 780px) {
              font-size: 13px;
              &:not(:last-child) {
                margin-right: 5px !important;
              }
            }
          }
        }
      }

      @media (max-width: 992px) {
        .custom-tabs-nav {
          flex-wrap: nowrap;
          display: flex;
          white-space: nowrap;
          width: 100% !important;
          justify-content: end;
          margin-bottom: 0;

          .nav-link {
            font-size: 13px;
            &:not(:last-child) {
              font-size: 13px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.event-edit-button {
  background-color: rgba(165, 151, 124, 0.15) !important;
  border: none;
  color: $brown !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 0px 30px;
  height: 2.2rem;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.4) !important;
  }
}

// .my-carousel-container {
//   display: flex;
//   width: 100%;
//   border: 1px solid $dark-grey;
//   border-radius: 6px;
//   padding: 20px;
//   background-color: $white;
//   box-shadow: $default-shadow;
//   margin-bottom: 1rem;
// }

// .carousel {
//   width: 100%;
//   position: relative;
// }
.carousel-content {
  display: flex;
  justify-content: space-between;
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-basis: 60%;
    padding: 20px;
  }
}

.image-content {
  flex-basis: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.image-placeholder {
  width: 100%;
  padding-top: 56.25%;
  background-color: #ddd;
  border: 1px solid #ccc;
}

// .carousel-control-prev-icon,
// .carousel-control-next-icon {
//   background-image: none;
//   color: $brown;
//   font-weight: bold;
//   font-size: 24px;
// }

// .carousel-control-prev::before {
//   color: $brown;
//   content: '<';
// }

// .carousel-control-next::before {
//   color: $brown;
//   content: '>';
// }

//Pagination dots
.carousel-indicators-container {
  text-align: center;
  flex-basis: 60%;
}

// .carousel-slider-wrapper {
//   width: 60%;
//   display: flex;
//   position: relative;
//   overflow: hidden;

//   & * {
//     box-sizing: border-box;
//   }

//   .carousel-slide {
//     background-color: $white;
//     margin: 0 auto;
//     display: none;
//     min-width: 100%;
//     text-align: center;

//     // the next class is required for the swipe effect
//     &.swipe {
//       user-select: none;
//       pointer-events: none;
//     }

//     &.active-slide {
//       display: block;
//       background-color: #f3f3f3;

//       &.next-active-slide {
//         position: absolute;
//         left: 0;
//         top: 0;
//       }

//       // styles for carousel image
//       img {
//         width: 100%;
//         display: block;
//       }

//       // styles for carousel caption
//       .carousel-caption {
//         position: absolute;
//         right: 35px;
//         bottom: 20px;
//         left: 35px;
//         z-index: 10;
//         padding-top: 20px;
//         padding-bottom: 20px;
//         color: $white;

//         .carousel-caption-title,
//         .carousel-caption-subtitle {
//           text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
//         }
//         .carousel-caption-title {
//           margin: 0 0 5px;
//         }

//         .carousel-caption-subtitle {
//           margin: 0;
//         }
//       }
//     }
//   }

//   // styles of the next and prev buttons
//   .carousel-control {
//     position: absolute;
//     top: 50%;
//     left: 0;
//     width: 65px;
//     font-size: 20px;
//     color: $white;
//     text-align: center;
//     text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
//     background-color: rgba(0, 0, 0, 0);
//     filter: alpha(opacity=50);
//     z-index: 1;

//     .left-triangle {
//       width: 0;
//       height: 0;
//       border-top: 30px solid transparent;
//       border-left: 35px solid $white;
//       border-bottom: 30px solid transparent;
//       position: absolute;
//       left: -1px;
//       cursor: initial;
//       transform: translateY(-50%);
//     }

//     .right-triangle {
//       width: 0;
//       height: 0;
//       border-top: 30px solid transparent;
//       border-right: 35px solid $white;
//       border-bottom: 30px solid transparent;
//       position: absolute;
//       right: -1px;
//       cursor: initial;
//       transform: translateY(-50%);
//     }

//     &.carousel-left-arrow {
//       button {
//         position: absolute;
//         left: -1px;
//         transform: translateY(-50%);
//       }
//     }

//     &.carousel-right-arrow {
//       right: 0;
//       left: auto;

//       button {
//         position: absolute;
//         right: -1px;
//         transform: translateY(-50%);
//       }
//     }

//     button {
//       background-color: transparent;
//       border: none;
//       outline: none;
//       padding: 0;
//       height: 30px;

//       &.padding-left-15 {
//         padding-left: 15px;
//       }

//       &.padding-right-15 {
//         padding-right: 15px;
//       }

//       svg {
//         width: 15px;
//         opacity: 0.5;
//         cursor: pointer;

//         &:hover {
//           opacity: 0.9;
//         }
//       }

//       i {
//         opacity: 0.5;
//         cursor: pointer;
//         color: $black;
//         font-size: 28px;

//         &:hover {
//           opacity: 1;
//         }
//       }
//     }
//   }

//   // styles of carousel indicators
//   .carousel-indicators {
//     position: absolute;
//     bottom: 10px;
//     z-index: 15;
//     padding-left: 0;
//     list-style: none;
//     margin-top: 0;
//     margin-bottom: 10px;

//     &.position-left {
//       left: 35px;
//     }

//     &.position-right {
//       right: 35px;
//     }

//     &.position-center {
//       left: 50%;
//       transform: translateX(-50%);
//     }

//     li {
//       display: inline-block;
//       width: 8px;
//       height: 8px;
//       margin: 1px;
//       text-indent: -999px;
//       cursor: pointer;
//       background-color: transparent;
//       border: 1px solid var(--indicatorsColor);
//       border-radius: 10px;
//       opacity: 1;

//       &:hover {
//         background-color: var(--indicatorsColor);
//         opacity: 0.7;
//       }

//       &.active {
//         width: 10px;
//         height: 10px;
//         margin: 0;
//         background-color: var(--indicatorsColor);
//         cursor: initial;
//         opacity: 1;
//       }
//     }
//   }
// }

// .learn-more-link {
//   color: $brown;
//   text-decoration: underline;
// }

// .carousel-controls {
//   position: absolute;
//   bottom: 0;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 60%;
// }

.my-carousel-container {
  display: flex;
  width: 100%;
  border: 1.5px solid rgba($dark-grey, 0.2);
  border-radius: 6px;
  // padding: 20px;
  background-color: $white;
  // box-shadow: $default-shadow;
  margin-bottom: 1rem;
}

.carousel-indicators-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-indicator-dot {
  width: 9px;
  height: 9px;
  margin: 0 5px;
  background-color: $light-grey;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
}

.carousel-indicator-dot.active {
  background-color: $brown;
}

//this is to hide the default indicators (arrows)
.carousel-control-prev,
.carousel-control-next {
  width: 0%;
}

.custom-arrow {
  background-image: none;
  color: $brown;
  font-weight: bold;
  height: 20px;
}

.next-arrow {
  height: 18px;
  width: 18px;
  transform: rotate(180deg);
  cursor: pointer;
  margin-left: 0.4rem;
}
.prev-arrow {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-right: 0.4rem;
}

.learn_more_img {
  width: 15px;
  height: 15px;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.carousel {
  width: 100%;
  .slide {
    width: 100%;
  }
}

.hide-dataGrid-toggle {
  .InovuaReactDataGrid__column-header__menu-tool {
    display: none;
  }
}
