.inline-preview-input {
  display: flex;
  justify-content: space-between;
  .nested {
    flex-direction: column;
  }
}

.inline-control-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inline-edit-btn {
  // align-self: end;
  align-self: start;
  &:hover {
    text-decoration: none;
  }
}
