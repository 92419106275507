@font-face {
  font-family: AvenirNext;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/AvenirNext/AvenirNextLTPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: AvenirNext-Medium;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/AvenirNext/AvenirNextLTPro-Medium.ttf');
}

@font-face {
  font-family: AvenirNext-Demi;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/AvenirNext/AvenirNextLTPro-Demi.otf') format('opentype');
}

@font-face {
  font-family: AvenirNext-Bold;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/AvenirNext/AvenirNextLTPro-Bold.otf') format('opentype');
}
