.notifications-loyout {
  header {
    background-color: $white !important;
    // padding-left: 30px !important;
  }
  flex-direction: column;
  background: $white;
  main {
    width: 100%;
    //padding: 0 10% !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 0px !important;
    align-self: center;

    .remove-btn {
      color: $red;
      &:hover {
        text-decoration: none;
      }
    }
    .data-table {
      min-width: 100% !important;
      //td{
      //  &:first-child {
      //    width: 200px;
      //  }
      //}
    }
  }
}

.support-panel-hidden {
  visibility: hidden;
  opacity: 0;
  // transition: visibility 0.5s, opacity 0.2s linear;
  transition: visibility 0s, opacity 0.2s linear;
}

.support-panel-visible {
  visibility: visible;
  opacity: 1;
  // transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.2s linear;
}

.support-panel-wrapper {
  position: fixed;
  right: 0px;
  width: 380px;
  height: 100vh;
  z-index: 10;
  background: white;
  padding: 9px 0 0 0;
  border-left: 1px solid rgba($dark, 0.3);
  box-shadow: 2px 2px 10px 0px #aaaaaa;
}

.support-panel-wrapper thead {
  display: none;
}

.support-panel-titlebar {
  display: flex;
  background: white;
  padding: 10px;
  justify-content: space-between;
  height: 51px;
  border-bottom: 1px solid rgba($dark, 0.3);
}

.support-panel-title {
  font-weight: 600;
  font-size: 16px;
}

.support-panel-img {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-right: 20px;
}

.support-panel-table {
  height: calc(100vh - 50px);
  overflow: hidden hidden;
  padding-right: 15px;

  .data-table tr td {
    font-size: 15px;
  }

  .notification-moment {
    margin-top: 6px;
    color: #8b959e;
    font-size: 13px;
  }
}

.support-panel-table:hover {
  overflow: hidden scroll;
  padding-right: 10px;
}

.support-panel-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.support-panel-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

.support-panel-table::-webkit-scrollbar-thumb {
  background-color: rgba(120, 128, 134, 0.5) !important;
  border-radius: 10px;
}
