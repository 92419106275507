.vehicle-card {
  margin: 6.5px 0;
  padding-right: 30px;
  flex-direction: row;
  border-radius: 2px;
  border: 1px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  // width: 700px;
  box-shadow: $card-shadow;
  &.car-rent {
    .sub-title {
      margin-top: 10px;
    }
    .card-title {
      // margin-top: 10px;
      margin-top: 0;
    }
  }
  &.active {
    box-shadow: none;
    border: 1px solid $black;
  }
  .vehicle-img-wrap {
    height: 100%;
    padding: 25px 0 52px 25px;
    margin: auto;
    width: 230px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;

    .vehicle-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .quote-img {
    width: 11%;
    height: 46%;
    margin-top: 7%;
  }

  .quote-internal-wrap {
    padding: 25px 0 15px 25px;
    width: 45%;
    font-size: 17px;
  }

  & .col {
    flex-grow: 2;
  }
  .col:last-child {
    flex-grow: 1;
  }

  .card-action {
    //display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 42px 0 38px;
    text-align: right;
    //min-width: 140px;
  }
  .card-action-weight {
    padding: 52px 0 36px !important;
    .text-brown {
      font-size: 12px !important;
      display: block !important;
    }
  }
  .card-body-mobile {
    justify-content: space-between;
    display: flex;

    .hint-text {
      line-height: 14px;
      margin-top: 10px;
    }
  }

  .card-body {
    padding: 40px 15px;
    width: 100%;
    .sub-title-electric {
      margin-bottom: 6px !important;
    }
    .sub-title {
      font-size: 12px;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: $dark;
      letter-spacing: 0.3px;
      .hint-text-year {
        font-size: 12px;
        color: #8b959e;
        float: right;
        border: 1px solid #8b959e;
        border-radius: 5px;
        padding-top: 2px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .vehicle-title {
      font-size: 15px;
      color: $dark-2;
      font-weight: normal;
      margin-top: -2px;
      // max-width: 210px;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      width: 100px;
      white-space: nowrap;
    }
    .electric-vehicle-title {
      margin-top: 2px;
    }

    .description {
      font-size: 14px;
      color: $dark-grey-2;
      margin-bottom: 11px;
    }
  }
  .hint-text-self {
    font-size: 15px;
    color: $dark-grey-2;
  }
  .hint-text {
    font-size: 14px;
    color: $dark-grey-2;
  }

  .hint-text-discount {
    text-decoration-line: line-through;
    font-size: 10px;
    color: $dark-grey-2;
    text-align: center;
  }

  .vehicle-value {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $black;

    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      margin-bottom: 3px;
      display: inline-block;
    }
  }
  .electric-vehicle {
    display: block;
    align-items: center;
    font-size: 12px;
    color: #28ae0f;
    margin-bottom: 6px;
    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: baseline;
    }
  }

  .lead {
    font-size: 15px;
    color: #303030;
    font-weight: normal;
    &.editable-vehicle-rate {
      // display: flex;
      display: inline-block;
      align-items: center;
    }
    .font-12 {
      font-size: 14px;
    }
  }

  .vehicle-selected {
    width: 38px;
    height: 38px;
    object-fit: contain;
  }

  .select-btn {
    min-width: 75px;
    height: 30px;
    &:focus {
      box-shadow: none;
    }
    &.select-vehicle {
      height: 40px;
      min-width: 140px;
      font-size: 14px;
    }
  }
  .booking-card-footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 20px;
    height: 20px;
    .expandable {
      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 20px;
        background-image: url('/images/icons/expand.png');
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat;
      }
      &.expanded {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    //flex-direction: column;
    padding-right: 0;
    &.active {
      border: 1px solid rgba($black, 0.5);
    }
    &.car-rent {
      .card-action {
        min-width: 135px;
      }
    }
    width: 100%;
    .vehicle-img-wrap {
      width: 28%;
      padding: 5px 10px 0;
    }
    .card-body {
      padding: 20px 8px;
      .vehicle-title {
        font-size: 1rem;
        max-width: 210px;
      }
      .vehicle-value {
        img {
          height: 12px;
        }
      }
      .electric-vehicle {
        img {
          height: 12px;
        }
      }
    }
    .card-action {
      justify-content: flex-start;
      padding-right: 20px;
      //padding-bottom: 40px;
      //padding-left: 30px;
      //padding-top: 0;
      //text-align: left;
      .lead {
        font-size: 0.8rem;
      }
    }
  }

  &.chat-vehicle-card {
    max-width: 360px;
    margin: 5px 0;
    padding: 0 5px;
    .vehicle-img-wrap {
      height: 100%;
      padding: 5px 0 5px 5px;
      margin: auto;
      width: 75px;
      flex-shrink: 0;
    }

    .card-action {
      //display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      text-align: right;
    }

    .hint-text {
      font-size: 10px;
    }
    .card-body {
      padding: 5px 5px;
      width: 100%;

      .sub-title {
        margin-bottom: 5px;
        font-size: 10px;
      }

      .booked-sub-title {
        display: flex;
        align-items: center;
        opacity: 1;
        max-width: 110px;
        justify-content: space-between;

        .booked-label {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: $brown;

          img {
            width: 15px;
            height: 15px;
            object-fit: contain;
          }
        }

        .service-label {
          padding: 2px;
          font-size: 8px;
        }
      }

      .vehicle-title {
        font-size: 12px;
        margin-bottom: 7px !important;
      }

      .description {
        font-size: 10px;
        margin-bottom: 5px;
      }
    }
    .vehicle-value {
      font-size: 10px;
      color: black;
      &:not(:last-child) {
        margin-right: 5px;
      }
      img {
        height: 10px;
        margin-right: 3px;
      }
    }
    .electric-vehicle {
      font-size: 10px;
      color: #28ae0f;
      &:not(:last-child) {
        margin-right: 5px;
      }
      img {
        height: 10px;
        margin-right: 3px;
      }
    }

    .lead {
      font-size: 12px;
      margin-bottom: 4px !important;
      margin-top: 4px;
    }

    .select-btn {
      min-width: 75px;
      height: 30px;
      padding-top: 4px;
      font-size: 13px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.vehicle-detail-card {
  //padding: 10px;

  border: 1px solid rgba($dark-grey, 0.1);
  box-shadow: $card-shadow;
  .card-body {
    .row {
      padding: 7px 30px 0 15px;
    }
  }
  .close-icon {
    position: absolute;
    top: 20px;
    right: 22px;
    img {
      width: 10px;
      height: 10px;
    }
  }
  label.expand-title {
    margin-bottom: 14px;
    font-size: 12px;
  }
  p {
    margin-bottom: 1.5px;
    font-size: 14px;
  }
}
.vehicle-card-selected {
  border: 1.5px solid $black !important;
}
.booking-col {
  max-width: 290px;
  //border: 1px solid rgba($dark-grey, 0.25);
  //border-radius: 2px;

  &:not(:first-child) {
    margin-left: -0.75rem;
  }
  &.car-rental {
    .booking-img-wrap img {
      width: 48px;
    }
  }
  &.chauffeur {
    .booking-img-wrap img {
      width: 36px;
    }
  }
  &.transfer {
    .booking-img-wrap img {
      width: 40px;
    }
  }
  &.events {
    .booking-img-wrap img {
      width: 32px;
    }
  }
}
.booking-card {
  padding: 30px;
  //box-shadow: $card-shadow;
  border-radius: 2px;
  border: 1px solid rgba($dark-grey, 0.25);
  align-items: center;
  overflow: hidden;
  height: 100%;
  cursor: pointer;

  .service-label {
    margin-bottom: 30px;
    align-self: flex-start;
  }

  .booking-img-wrap {
    //width: 33%;
    //min-width: 33%;
    height: 95px;
    display: flex;
    .booking-img {
      //width: 100%;
      object-fit: contain;
    }
    .booking-card-tag {
      border: 1px solid #000000;
      padding: 3px 6px;
      border-radius: 2px;
      font-size: 13px;
    }
  }

  & .col {
    flex-grow: 2;
  }
  .col:last-child {
    flex-grow: 1;
    margin-top: auto;
  }

  .card-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-body {
    padding: 0 10px 20px;
    .card-logo {
      display: none;
    }
    .booking-title {
      font-size: 20px;
      color: $dark-2;
      font-weight: normal;
      //text-align: center;
    }

    .description {
      font-size: 15px;
      color: $dark-grey-2;
      margin-bottom: 11px;
      line-height: 24px;
      //text-align: center;
    }
  }

  .hint-text {
    font-size: 15px;
    color: $dark-grey;
  }

  .lead {
    font-size: 25px;
  }

  .booking-selected {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  &:hover {
    box-shadow: $card-shadow;
  }
}

.vehicle-card-desktop {
  margin: 6.5px 0;
  padding-right: 30px;
  flex-direction: row;
  border-radius: 2px;
  border: 1px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  width: 700px;
  box-shadow: $card-shadow;
  &.car-rent {
    .sub-title {
      margin-top: 10px;
    }
    .card-title {
      // margin-top: 10px;
      margin-top: 0;
    }
  }
  .vehicle-img-wrap {
    height: 100%;
    padding: 25px 0 52px 25px;
    margin: auto;
    width: 230px;
    flex-shrink: 0;

    .vehicle-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  & .col {
    flex-grow: 2;
  }
  .col:last-child {
    flex-grow: 1;
  }

  .card-action {
    //display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 42px 0 38px;
    text-align: right;
    //min-width: 140px;
  }
  .card-action-weight {
    padding: 52px 0 36px !important;
    .text-brown {
      font-size: 12px !important;
      display: block !important;
    }
  }
  .card-body-mobile {
    justify-content: space-between;
    display: flex;

    .hint-text {
      line-height: 14px;
      margin-top: 10px;
    }
  }

  .card-body {
    padding: 40px 15px;
    width: 100%;
    .sub-title-electric {
      margin-bottom: 6px !important;
    }
    .sub-title {
      font-size: 12px;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: $dark;
      letter-spacing: 0.3px;
      .hint-text-year {
        font-size: 12px;
        color: #8b959e;
        float: right;
        border: 1px solid #8b959e;
        border-radius: 5px;
        padding-top: 2px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .vehicle-title {
      font-size: 15px;
      color: $dark-2;
      font-weight: normal;
      margin-top: -2px;
      max-width: 210px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .electric-vehicle-title {
      margin-top: 2px;
    }

    .description {
      font-size: 14px;
      color: $dark-grey-2;
      margin-bottom: 11px;
    }
  }
  .hint-text-self {
    font-size: 15px;
    color: $dark-grey;
  }
  .hint-text {
    font-size: 14px;
    color: $dark-grey;
  }

  .hint-text-discount {
    text-decoration-line: line-through;
    font-size: 10px;
    color: $dark-grey;
    text-align: center;
  }

  .vehicle-value {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $black;

    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      margin-bottom: 3px;
      display: inline-block;
    }
  }
  .electric-vehicle {
    display: block;
    align-items: center;
    font-size: 12px;
    color: #28ae0f;
    margin-bottom: 6px;
    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: baseline;
    }
  }

  .lead {
    font-size: 15px;
    color: #303030;
    font-weight: normal;
    &.editable-vehicle-rate {
      // display: flex;
      display: inline-block;
      align-items: center;
    }
    .font-12 {
      font-size: 14px;
    }
  }

  .vehicle-selected {
    width: 38px;
    height: 38px;
    object-fit: contain;
  }

  .select-btn {
    min-width: 75px;
    height: 30px;
    &:focus {
      box-shadow: none;
    }
    &.select-vehicle {
      height: 40px;
      min-width: 140px;
      font-size: 14px;
    }
  }
  .booking-card-footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 20px;
    height: 20px;
    .expandable {
      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 20px;
        background-image: url('/images/icons/expand.png');
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat;
      }
      &.expanded {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  // @include media-breakpoint-down(md) {
  //flex-direction: column;
  padding-right: 0;
  &.active {
    border: 1px solid rgba($black, 0.5);
  }
  &.car-rent {
    .card-action {
      min-width: 135px;
    }
  }
  width: 100%;
  .vehicle-img-wrap {
    width: 50% !important;
    padding: 5px 10px 0;
  }
  .card-body {
    padding: 20px 8px;
    .vehicle-title {
      font-size: 1rem;
      max-width: 210px;
    }
    .vehicle-value {
      img {
        height: 12px;
      }
    }
    .electric-vehicle {
      img {
        height: 12px;
      }
    }
  }
  .card-action {
    justify-content: flex-start;
    padding-right: 20px;
    //padding-bottom: 40px;
    //padding-left: 30px;
    //padding-top: 0;
    //text-align: left;
    .lead {
      font-size: 0.8rem;
    }
  }
  // }

  &.chat-vehicle-card {
    max-width: 360px;
    margin: 5px 0;
    padding: 0 5px;
    .vehicle-img-wrap {
      height: 100%;
      padding: 5px 0 5px 5px;
      margin: auto;
      width: 75px;
      flex-shrink: 0;
    }

    .card-action {
      //display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      text-align: right;
    }

    .hint-text {
      font-size: 10px;
    }
    .card-body {
      padding: 5px 5px;
      width: 100%;

      .sub-title {
        margin-bottom: 5px;
        font-size: 10px;
      }

      .booked-sub-title {
        display: flex;
        align-items: center;
        opacity: 1;
        max-width: 110px;
        justify-content: space-between;

        .booked-label {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: $brown;

          img {
            width: 15px;
            height: 15px;
            object-fit: contain;
          }
        }

        .service-label {
          padding: 2px;
          font-size: 8px;
        }
      }

      .vehicle-title {
        font-size: 12px;
        margin-bottom: 7px !important;
      }

      .description {
        font-size: 10px;
        margin-bottom: 5px;
      }
    }
    .vehicle-value {
      font-size: 10px;
      color: black;
      &:not(:last-child) {
        margin-right: 5px;
      }
      img {
        height: 10px;
        margin-right: 3px;
      }
    }
    .electric-vehicle {
      font-size: 10px;
      color: #28ae0f;
      &:not(:last-child) {
        margin-right: 5px;
      }
      img {
        height: 10px;
        margin-right: 3px;
      }
    }

    .lead {
      font-size: 12px;
      margin-bottom: 4px !important;
      margin-top: 4px;
    }

    .select-btn {
      min-width: 75px;
      height: 30px;
      padding-top: 4px;
      font-size: 13px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
