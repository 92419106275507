.removable-image {
  display: block;
  .btn.remove-image-btn {
    display: block;
    font-size: 10px;
    line-height: 14px;
    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
