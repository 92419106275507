.auth-page {
  background-color: $white;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: flex-end;

  .image-container {
    width: 50%;
    min-height: 100%;
    // background-image: url('/images/login.png');
    // background-image: url('/images/login1.png');
    // background-size: cover;
    // background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.image-container-GB {
      background-image: url('/images/GB.png');
      background-size: contain;
    }
    &.image-container-FR {
      background-image: url('/images/FR.png');
      background-size: contain;
    }
    &.image-container-US {
      background-image: url('/images/US.png');
      background-size: contain;
    }
    &.image-container-US-Miami {
      background-image: url('/images/Miami.jpg');
      background-size: contain;
    }
    &.image-container-MX {
      background-image: url('/images/MX.png');
      background-size: contain;
    }
    &.image-container-REST {
      background-image: url('/images/login1.png');
      background-size: contain;
    }
    &.image-container-Partner {
      background-image: url('/images/login.png');
      background-size: cover;
    }
  }
  .image-container-new {
    width: 50%;
    min-height: 100%;
    // background-image: url('/images/login.png');
    // background-image: url('/images/login1.png');
    // // background-size: cover;
    // background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.image-container-GB {
      background-image: url('/images/GB.png');
      background-size: contain;
    }
    &.image-container-FR {
      background-image: url('/images/FR.png');
      background-size: contain;
    }
    &.image-container-US {
      background-image: url('/images/US.png');
      background-size: contain;
    }
    &.image-container-US-Miami {
      background-image: url('/images/Miami.jpg');
      background-size: contain;
    }
    &.image-container-MX {
      background-image: url('/images/MX.png');
      background-size: contain;
    }
    &.image-container-REST {
      background-image: url('/images/login1.png');
      background-size: contain;
    }
  }
  .image-container-new-partner {
    width: 50%;
    min-height: 100%;
    background-image: url('/images/login_partner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .auth-container-partner {
    width: 50%;
    height: 100vh;
    overflow-y: auto;
  }
  .auth-container {
    //min-height: 100vh;
    width: 50%;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
  }
  .auth-container-100 {
    //min-height: 100vh;
    width: 100%;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
  }

  .login-img {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .scrollable-page-content {
    height: 100vh;
    overflow: auto;
  }

  .scrollable-page-content.light-theme {
    height: calc(100vh - 60px);
    width: 100%;
  }

  .scrollable-page-content::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  .scrollable-page-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  .scrollable-page-content::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px;
  }

  .scrollable-page-content-without-supportbar {
    height: 100vh !important;
    width: 100%;
  }
  .auth-wrap {
    width: 400px;
    max-width: 400px;
    margin: 0 auto;

    a {
      color: #a79779;
    }
    .rolzo-header {
      width: 100%;
      overflow: auto;
      &:first-child {
        margin-top: 150px;
      }
      img {
        max-width: 160px;
      }
    }

    .btn.login-btn {
      width: 100%;
      font-size: 15px;
      margin-top: 14px;
    }
  }

  form {
    width: 100%;
    margin: 30px 0px 16px 0;

    .field-wrap {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .rolzo-footer {
    width: 100%;
    overflow: hidden;
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    background: $light-2;
  }
}

.client-register-continue {
  width: 100%;
  max-width: 270px;
  margin-left: 9%;
}
.marginRight-10 {
  margin-right: 10px !important;
}
.continue-right {
  float: right;
}

.auth-page {
  .phone-field-row {
    .col-md-3 {
      flex: 0 0 35%;
      max-width: 35%;
    }
    .col-md-9 {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }
}

.sign-up-form {
  padding: 25px 0 25px 0 !important;
}

.sign-up-form-no-bottom {
  padding: 25px 0 0px 0 !important;
}

//Sign up page
.signUp-radio-wrapper {
  display: flex;
  justify-content: space-between;
}

.sign-up-radio {
  flex: 1;
  text-align: center;
  border: 1px solid $dark-grey;
  border-radius: 2px;
  padding: 1rem 0.5rem 1rem 0.5rem;
}
.sign-up-radio:first-child {
  margin-right: 4px;
}

.sign-up-radio:last-child {
  margin-left: 4px;
}
.custom-radio-signUp {
  margin-bottom: 0px !important;
  .custom-control-label {
    padding-top: 0.15rem;
  }
}
@media (max-width: 768px) {
  .custom-radio-signUp {
    margin-bottom: 0px !important;
    .custom-control-label {
      padding-top: 0.15rem;
      font-size: 15px !important;
    }
  }
}
