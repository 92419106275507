$breakpoints: (
  'xsmall': 360px,
  'small': 480px,
  'medium': 768px,
  'large': 991px,
  'ipad': 1024px,
  'xlarge': 1200px,
  'xxlarge': 1440px,
);

@mixin respond($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);
  @if $value {
    @media (min-width: $value) {
      @content;
    }
  } @else {
    @warn "Breakpoint `#{$breakpoint}` not found in `$breakpoints`.";
  }
}
