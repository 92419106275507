.auth-page {
  .page-title {
    background: #fafafa;
    width: 100%;
    h1 {
      padding: 70px 48px;
      font-size: 34px;
      align-items: center;
      img {
        width: 60px;
      }
    }
  }
  input {
    background-image: none !important;
  }
  .form-block {
    margin: 0 50px;
    padding: 50px 0;
  }
}
.register-footer {
  padding: 0 50px 50px;
  //text-align: right;
}

.partner-auth {
  font-size: 34px !important;
  width: 100%;
  padding-top: 0.25rem !important;
  padding-left: 6rem !important;
  h1 {
    padding: 70px 48px;
    font-size: 34px;
    align-items: center;
    img {
      width: 60px;
    }
  }
}
.partner-sub-header {
  font-weight: lighter;
  white-space: pre-wrap;
  line-height: 32px;
  margin-left: 2.1rem !important;
}
