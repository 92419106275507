.invoice-download-link {
  color: #a79779;
  font-size: 10px;
  &:hover {
    text-decoration: none;
  }
}
.invoice-type {
  font-size: 8px;
  color: #8b959e;
}

.custom-control.custom-radio {
  label {
    cursor: default !important;
    font-size: 14px;
  }
  &:hover {
    box-shadow: none;
  }
}