.page-loading {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($light, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
