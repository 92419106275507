.row-top-bottom-border {
  border-bottom-style: groove;
  border-bottom-width: thin;
  border-top-style: groove;
  border-top-width: thin;
  padding-top: 13px;
}
.col-right-left-padding {
  padding-right: 30px;
  padding-left: 30px;
}
.col-no-padding {
  padding-right: 0px;
  padding-left: 0px;
}
.col-no-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.row-border-bottom {
  border-bottom-style: groove;
  border-bottom-width: thin;
}
#bg-nested-dropdown-ll {
  height: 50px;
  border-radius: 2px;
  border: 0.5px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb;
  padding: 0;
  color: #0c0c0c;

  ::after {
    margin-left: 100px;
  }
}

.dashboard-container {
  padding: 8%;
}
.dashboard-bg {
  background-color: #e6e6e6;
}
.d-box {
  background-color: white;
  padding: 4%;
  //margin: 0.5%;
  border: #e6e6e6;
  border-style: solid;
  border-bottom-width: 18px;
}

.dropdown-col {
  & .dropdown-menu {
    min-width: 37rem !important;
    //left: 70%!important;
    transform: translate3d(-66%, 50px, 0px) !important;
  }
}

.top-bottom-margin {
  margin-top: 8%;
  margin-bottom: 8%;
}
.colored-margin {
}
