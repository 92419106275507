h4.sub-header {
  font-weight: lighter;
}

h4.sub-header-rates {
  font-weight: lighter;
}

a.invite-new-user {
  font-size: 15px;
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.profile-link {
  display: flex;
  align-items: center;
  width: 450px;
  max-width: 100%;
  height: 100px;
  border: 1px solid rgba($dark-grey, 0.25);
  //box-shadow: $default-shadow;
  // margin-bottom: 20px;
  margin-left: 0;
  padding-left: 30px;
  border-radius: 2px;
  //background-color: $light-2;
  &:hover {
    text-decoration: none;
    box-shadow: $card-shadow;
  }
  .profile-info {
    padding-left: 20px;
    p {
      padding: 0;
      margin: 0;
      &.header {
        font-size: 14px;
        color: $black;
      }
      &.description {
        font-size: 12px;
        color: $dark-grey;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  .notifications {
    border: 1px solid rgba($dark, 0.25);
    border-radius: 50%;
    img {
      width: 45px;
      height: 45px;
      padding: 10px;
      border: none !important;
    }
  }
  .billing {
    border: 1px solid rgba($dark, 0.25);
    border-radius: 50%;
    img {
      width: 45px;
      height: 45px;
      padding: 10px;
      border: none !important;
      translate: 2px;
    }
  }
  .preference {
    border: 1px solid rgba($dark, 0.25);
    border-radius: 50%;
    img {
      width: 45px;
      height: 45px;
      padding: 10px;
      border: none !important;
    }
  }
  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border: 1px solid rgba($dark, 0.25);
  }
}

.submit-btn-change-pass {
  height: 40px !important;
}

.NotificationSettings {
  .BookingUpdates {
    span {
      font-size: 20px !important;
      color: black;
    }
  }
  .icons {
    .email-icon {
      width: 17px !important;
      vertical-align: baseline;
      margin-right: 5px;
    }
    img {
      width: 15px;
      vertical-align: baseline;
      margin-right: 5px;
    }
    span {
      color: #8b959e;
    }
  }
  .checkBox-Center {
    margin: 0% 40%;
  }
  thead {
    th {
      border-bottom: none !important;
    }
  }
  td {
    background-color: transparent;
  }
  tbody {
    tr {
      td {
        border: none !important;
      }
    }
  }
  th {
    border: none !important;
    td {
      border-radius: none !important;
    }
  }

  .custom-control-label:after {
    // border-radius: 2px;
    background-color: #fbfbfb;
    border: 1px solid #8b959e;
  }
  .custom-control-input:checked ~ .custom-control-label:after {
    border-color: black;
    background-size: 9px 11px;
  }
}

.earnings-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  gap: 15px;
  padding-right: 0.938rem;

  .grid-item {
    box-shadow: $default-shadow;
    background-color: $light-2;
    border-color: transparent;
    padding: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .top-left {
    grid-column: 1 / span 2;
    margin-bottom: 1rem;
  }

  .top-right {
    grid-column: 3 / span 2;
    margin-bottom: 1rem;
    display: grid;
    grid-template-rows: repeat(2, 1fr); /* creates two rows */
    gap: 10px; /* space between the grid items */

    .pending-expected {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .pending-expected-body {
      font-size: 20px;
      display: flex;
      justify-content: flex-end;
      padding: 0px;

      .amount {
        font-weight: normal;
        margin-right: 0.3em;
      }

      .currency {
        font-weight: normal;
      }
    }
  }

  .bottom-left,
  .bottom-center-left,
  .bottom-center-right,
  .bottom-right {
    grid-column: auto;
  }

  .card-header {
    font-size: 14px;
    margin-bottom: 0.5rem;
    padding: 0px;
    color: $dark-grey;
    background-color: transparent;
    border-bottom: none;
    margin-bottom: 2rem;
  }

  .card-body {
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    padding: 0px;

    .amount {
      font-weight: normal;
      margin-right: 0.3em;
    }

    .currency {
      font-weight: normal;
    }
  }

  .card-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .increase {
    color: $success;
    font-size: 0.8rem;
  }
  .decrease {
    color: $danger;
    font-size: 0.8rem;
  }
}

.account-payout-card {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba($dark-grey, 0.25);
  max-height: 12.938rem;
  max-width: 22.813rem;
  padding: 1.5rem;
  border-radius: 2px;
  img {
    height: 1.6rem;
    width: 1.6rem;
    margin-bottom: 1rem;
  }
}

.account-tabs {
  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px;

    .nav-link {
      background-color: transparent;
      color: rgba($dark-grey, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;

      &:hover {
        color: rgba($dark-grey, 0.7);
      }

      &.active {
        background-color: $white;
        color: $dark-3;
        box-shadow: $default-shadow;
      }
    }

    &.light-tabs {
      border-bottom: 1px solid rgba($dark-grey, 0.3);
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 16px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        color: $dark-grey;
        opacity: 0.55;
        padding: 7px 7px 14px 7px;
        border: none;
        &.active {
          background: transparent;
          border: none;
          border-bottom: 3px solid $black;
          box-shadow: none;
          color: $dark-2;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600;
        }

        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.preview-card {
  height: 17rem;
  width: 23.7rem;
  margin-left: 1.2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(139, 149, 158, 0.3);
  padding: 1.6rem;
  border-radius: 2px;
  margin-top: 1rem;
  align-items: center;
  box-shadow: $default-shadow;
}

.preview-card .top-image-container {
  width: 80px;
  height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.preview-card .bottom-image-container {
  width: 80px;
  height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-card .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: black;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
}

.preview-card .top-image {
  max-height: 100%; /* Ensure the image fits within the reserved space */
}

.preview-card .bottom-image {
  max-height: 100%; /* Ensure the image fits within the reserved space */
  align-self: center;
}

.my-profile-mobile {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // margin-top: 20px;
  margin-bottom: 2rem;

  .layout-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    width: 80px;
    min-width: 80px;
    background: $dark-grey;
    opacity: 0.5;
    border-radius: 50%;
    margin-right: 0px !important;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    font-size: 33px;
    margin-bottom: 1.5rem;
  }
  .header {
    font-size: 16px;
    padding-bottom: 0.4rem;
    display: flex;
  }
}
.my-profile-section {
  margin-top: 4rem !important;
}
.host-agency-bank {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1rem 7rem 1rem 1rem;
  background-color: rgba(139, 149, 158, 0.05);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 100%;
}
.host-agency-bank-title {
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.form-field-with-remove {
  display: flex;
  align-items: center;
  .col-md-4 {
    flex: 0 0 37.8%;
    max-width: 37.8%;
    display: flex;
    align-items: center;
  }
  .form-group {
    width: inherit;
    margin-right: 0.5rem;
  }
}

.toolTip-settings {
  border: 1.5px solid rgba($dark-grey, 0.2);
  margin-left: 1em;
  margin-top: 0.5em;
  padding: 0.2rem;
  .toolTip-light-grey {
    display: inline-block;
    text-align: left;
    font-size: 13px;
    // color: #636d77;
    color: $black;
    text-align: left;
  }
}

.radio-prefrence-margin {
  margin-top: 0.7rem;
  margin-bottom: 0px;
}

.register-cool-responsive {
  max-width: 730px;
  // flex: 0 0 50%; // Default for screens <= 768px
  // max-width: 50%;

  // @media (min-width: 1520px) {
  //   flex: 0 0 41.66667%;
  //   max-width: 41.66667%;
  // }
  // @media (min-width: 1520px) and (max-width: 2160px) {
  //   flex: 0 0 41.66667%;
  //   max-width: 41.66667%;
  // }
  // @media (min-width: 2160px) {
  //   flex: 0 0 33%;
  //   max-width: 33%;
  // }
}
