.currency-heading {
  font-size: 18px;
  margin-bottom: 0.5rem;
}
.currency-discription {
  font-size: 14px;
}
.currency-DropDown {
  margin-left: 5%;
}
@media (max-width: 768px) {
  .currency-heading {
    font-size: 16px;
    font-weight: 400;
    // margin-top: 0.5%;
  }
  .currency-discription {
    font-size: 14px;
  }
  .currency-DropDown {
    margin-left: 12%;
  }
}
