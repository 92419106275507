.rolzo-gpt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.chat-container-gpt {
  width: 75%;
  height: 75%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
}

.messages-gpt {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.messageGPT {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  max-width: 70%;
}

.messageGPT.user {
  align-self: flex-end;
  background-color: #d3d3d3;
  color: black;
}

.messageGPT.bot {
  align-self: flex-start;
  background-color: #a79779;
  color: white;
}

.input-container-gpt {
  display: flex;
  border-top: 1px solid #e9ecef;
  padding: 10px;
}

.input-gpt {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.button-gpt {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #a79779;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-gpt:hover {
  background-color: #e6be00;
}

.icon-container-gpt {
  position: absolute;
  top: 75px;
  right: 193px;
}

.info-icon-gpt {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.tooltip-gpt {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}
