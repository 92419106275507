.notifications-board-wrap {
  position: absolute;
  bottom: 100px;
  right: 25px;
  width: 420px;
  .float-btn-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 60px;
    width: 60px;
    .unread-indicator {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background: red;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
      top: 10px;
      right: 10px;
      padding-top: 2px;
    }
  }
  .notifications-board {
    width: 350px;
    .notification-card {
      margin: 5px 0;
      flex-direction: row;
      box-shadow: $card-shadow;
      border-radius: 2px;
      border: 1px solid rgba($dark-grey, 0.25);
      overflow: hidden;

      .card-body {
        padding: 0 10px;
        width: 100%;
        min-width: 350px;

        .notification-title {
          font-size: 20px;
          color: $dark-2;
          font-weight: normal;
        }
      }

      .hint-text {
        font-size: 15px;
        color: $dark-grey;
      }
    }
  }
}

@media (max-width: 768px) {
  .notifications-tr {
    top: 30px !important;
  }
}
