.close-icon-btn {
  // position: absolute;
  // top: 20px;
  // right: 22px;
  margin-left: 97%;
  margin-bottom: 1.5%;
  img {
    width: 10px !important;
    height: 10px !important;
  }
  cursor: pointer;
}
.heading-benefits-card {
  img {
    max-height: 33px !important;
  }
}
.benefits-header-text-with-icon {
  margin-top: 2.5%;
  padding-left: 2%;
  margin-bottom: 5%;
  font-size: 14px !important;
  font-weight: 500;
  word-break: break-word;
}
.benefits-header-text-without-icon {
  font-size: 14px !important;
  font-weight: 500;
  word-break: break-word;
}
.benefits-list-item {
  margin-left: 4.6rem;
  padding-top: 1%;
  img {
    max-height: 15px;
    margin-top: 1%;
  }
  span {
    margin-top: 1%;
    margin-left: 1%;
  }
}
