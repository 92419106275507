.float-btn-wrap {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 48px;
  right: 25px;

  &.notification-btn-wrap {
    bottom: 125px;
  }

  .unread-indicator {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: red;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    top: 10px;
    right: 12px;
    //padding-top: 2px;
  }

  .float-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    //box-shadow: $default-shadow;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.notification-btn {
      background: #a79779;
      border: none;

      img {
        //width: 50%;
        //height: 50%;
        object-fit: contain;
      }
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

.no-title-main {
  .booking-preview-info {
    .col-booking-detail {
      height: calc(100vh - 130px) !important;
    }

    .chat-list-container {
      height: calc(100vh - 290px) !important;
    }

    .messages-list {
      height: calc(100vh - 400px) !important;
    }
  }
}

.benefits-code {
  padding-right: 2.7rem;
  padding-left: 2.7rem;
}
.benefits-code-button {
  padding-right: 1.9rem;
  padding-left: 2.6rem;
}

.booking-preview-info {
  // max-width: 1040px;

  .col-booking-detail {
    min-height: 150px;
    height: calc(100vh - 230px);
    overflow: auto;
    padding-bottom: 40px;
  }

  .col-booking-detail::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .col-booking-detail::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  .col-booking-detail::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px;
  }

  .vehicle-img-small {
    width: 190px;
  }

  .booking-preview-info-section {
    padding: 24px;
    background-color: $light-3;
    font-size: 16px;
    border-radius: 4px;
    .grey-field-input {
      input,
      label,
      .react-select__control,
      .react-tel-input.form-control {
        background-color: white !important;
      }
    }
    .react-tel-input {
      .selected-flag {
        background-color: white !important;
      }
    }
    .react-select-container .react-select__control {
      background-color: white !important;
    }
    input,
    select {
      &.custom-datepicker-control {
        &:read-only {
          background-color: white;
        }
      }
      &.grey-field-input {
        background-color: white !important;
      }

      &.form-control,
      &.react-phone-number-input__input.react-phone-number-input__phone {
        background-color: white;
        &:read-only,
        &:disabled {
          background-color: white;
        }
      }
    }
    .cancel-booking {
      color: $red;

      &:hover {
        text-decoration: none;
        color: $red;
      }
    }

    .mt-20 {
      margin-top: 20px;
    }

    .chat-list-container {
      height: calc(100vh - 370px);
      width: 100%;

      .ScrollbarsCustom {
        .ScrollbarsCustom-Wrapper {
          //position: relative !important;

          .ScrollbarsCustom-Scroller {
            //position: relative !important;
          }
        }

        .ScrollbarsCustom-Track {
          background-color: $white !important;
          opacity: 0;
          transition: opacity 200ms ease;
          transform: translateY(0px);
          width: 8px !important;
          padding-left: 2px;

          .ScrollbarsCustom-Thumb {
            width: 100%;
            background-color: rgba(
              var(--sk_primary_foreground, 29, 28, 29),
              0.52
            );
          }
        }

        &:hover {
          .ScrollbarsCustom-Track {
            opacity: 1;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    label {
      font-size: 10px;
    }
    .benefit-Fields {
      div:last-child {
        label {
          font-size: 10px;
          color: #47dc95 !important;
        }
      }
    }
    &.total-amount {
      //padding: 40px 0;
    }

    .section-header {
      font-size: 20px;
      line-height: 43px;
      // margin-bottom: 40px;
      font-weight: 400;
      &.expandable {
        cursor: pointer;
        padding-right: 40px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background-image: url('/images/icons/caret-down.svg');
          background-size: contain;
          background-position: right 0 center;
          background-repeat: no-repeat;
        }

        &.expanded {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &.booking-header {
        display: flex;
        align-items: center;
        //@media screen and (max-width: 576px) {
        //  justify-content: center;
        //}
        .service-label {
          line-height: 15px !important;
          margin-left: 25px;
        }
      }
      &.closing-header {
        display: inline-block;
      }
    }
    .section-sub-header {
      font-size: 18px;
      line-height: 43px;
      margin-bottom: 5px;
      font-weight: 400;
      &.expandable {
        cursor: pointer;
        padding-right: 40px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background-image: url('/images/icons/caret-down.svg');
          background-size: contain;
          background-position: right 0 center;
          background-repeat: no-repeat;
        }

        &.expanded {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &.booking-header {
        display: flex;
        align-items: center;
        //@media screen and (max-width: 576px) {
        //  justify-content: center;
        //}
        .service-label {
          line-height: 15px !important;
          margin-left: 25px;
        }
      }
      &.closing-header {
        display: inline-block;
      }
    }
    .booking-preview-info-section-close {
      float: right;
      margin-top: 8px;
      img {
        width: 12px;
      }
    }
    .form-group {
      label {
        //letter-spacing: 0.3px;
      }

      p {
        font-size: 15px;
        text-transform: capitalize;
      }
      pre {
        font-size: 15px;
        //text-transform: capitalize;
      }
    }

    .model-row {
      margin-bottom: 15px;

      .form-group {
        //margin-bottom: 15px !important;
      }
    }

    .vehicle-value {
      img {
        width: auto;
        height: 11px;
        margin-right: 5px;
        margin-bottom: 3px;
        display: inline-block;
      }

      color: $black;
    }
    .electric-vehicle {
      img {
        width: auto;
        height: 10px;
        margin-right: 5px;
        // margin-bottom: 3px;
        display: inline-block;
        margin-left: 5px;
        vertical-align: baseline;
      }

      color: #28ae0f;
    }
    //
    //p {
    //  margin-bottom: 0;
    //}
    .benefits-body {
      font-size: 15px !important;
      margin-top: 1%;
      padding-left: 1%;
    }

    .black-Button {
      border-radius: 2px;
      //border: 1px solid rgba(#000000, 0.1) !important;
      background-color: white !important;
      color: $black;
      // width: 32% !important;
      border: 1px dashed !important;
    }
    .black-button-width {
      max-width: fit-content;
    }
    .meet-and-assist-description {
      font-size: 14px;
      color: $dark-2;
      padding-left: 5px;
      display: block;
      line-height: 27px !important;
    }
    .meet-and-assist-features {
      padding-left: 5px;
      padding-top: 2.5em;
      padding-bottom: 2em;

      div {
        background: url('/images/Tick_Icon.png');
        font-size: 14px;
        background-position: top 3px left 0px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 28px;
        margin-bottom: 4px;
      }
    }
    .meet-and-assist-confirm {
      line-height: 34px !important;
      margin-bottom: 0px !important;
    }
    .meet-and-assist-confirm-inner {
      padding-left: 5px !important;
      padding-top: 2em !important;
      padding-bottom: 2em !important;
    }
    .meet-assist-payment-block {
      font-size: 15px;
      margin-top: 1em;
      display: flex;
      justify-content: space-between;
    }

    .status-paid {
      font-size: 13px;
      color: $success;
    }
    .status-unpaid {
      font-size: 13px;
      color: $dark;
    }
  }

  .booking-preview-info-section-booking-summary {
    padding: 24px;
    // was 35 now 29 & was 38 now 32
    background-color: $light-3;
    font-size: 15px;

    .cancel-booking {
      color: $red;

      &:hover {
        text-decoration: none;
        color: $red;
      }
    }

    .mt-20 {
      margin-top: 20px;
    }

    .chat-list-container {
      height: calc(100vh - 370px);
      width: 100%;

      .ScrollbarsCustom {
        .ScrollbarsCustom-Wrapper {
          //position: relative !important;

          .ScrollbarsCustom-Scroller {
            //position: relative !important;
          }
        }

        .ScrollbarsCustom-Track {
          background-color: $white !important;
          opacity: 0;
          transition: opacity 200ms ease;
          transform: translateY(0px);
          width: 8px !important;
          padding-left: 2px;

          .ScrollbarsCustom-Thumb {
            width: 100%;
            background-color: rgba(
              var(--sk_primary_foreground, 29, 28, 29),
              0.52
            );
          }
        }

        &:hover {
          .ScrollbarsCustom-Track {
            opacity: 1;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    label {
      font-size: 10px;
    }
    .benefit-Fields {
      div:last-child {
        label {
          font-size: 10px;
          color: #47dc95 !important;
        }
      }
    }
    &.total-amount {
      //padding: 40px 0;
    }

    .section-header {
      font-size: 20px;
      line-height: 43px;
      // margin-bottom: 40px;
      font-weight: 400;
      &.expandable {
        cursor: pointer;
        padding-right: 40px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background-image: url('/images/icons/caret-down.svg');
          background-size: contain;
          background-position: right 0 center;
          background-repeat: no-repeat;
        }

        &.expanded {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &.booking-header {
        display: flex;
        align-items: center;
        //@media screen and (max-width: 576px) {
        //  justify-content: center;
        //}
        .service-label {
          line-height: 15px !important;
          margin-left: 25px;
        }
      }
      &.closing-header {
        display: inline-block;
      }
    }
    .section-sub-header {
      font-size: 18px;
      line-height: 43px;
      margin-bottom: 5px;
      font-weight: 400;
      &.expandable {
        cursor: pointer;
        padding-right: 40px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background-image: url('/images/icons/caret-down.svg');
          background-size: contain;
          background-position: right 0 center;
          background-repeat: no-repeat;
        }

        &.expanded {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &.booking-header {
        display: flex;
        align-items: center;
        //@media screen and (max-width: 576px) {
        //  justify-content: center;
        //}
        .service-label {
          line-height: 15px !important;
          margin-left: 25px;
        }
      }
      &.closing-header {
        display: inline-block;
      }
    }
    .booking-preview-info-section-close {
      float: right;
      margin-top: 8px;
      img {
        width: 12px;
      }
    }
    .form-group {
      label {
        //letter-spacing: 0.3px;
      }

      p {
        font-size: 15px;
        text-transform: capitalize;
      }
      pre {
        font-size: 15px;
        //text-transform: capitalize;
      }
    }

    .model-row {
      margin-bottom: 15px;

      .form-group {
        //margin-bottom: 15px !important;
      }
    }

    .vehicle-value {
      img {
        width: auto;
        height: 11px;
        margin-right: 5px;
        margin-bottom: 3px;
        display: inline-block;
      }

      color: $black;
    }
    .electric-vehicle {
      img {
        width: auto;
        height: 10px;
        margin-right: 5px;
        // margin-bottom: 3px;
        display: inline-block;
        margin-left: 5px;
        vertical-align: baseline;
      }

      color: #28ae0f;
    }
    //
    //p {
    //  margin-bottom: 0;
    //}
    .benefits-body {
      font-size: 15px !important;
      margin-top: 1%;
      padding-left: 1%;
    }

    .black-Button {
      border-radius: 2px;
      //border: 1px solid rgba(#000000, 0.1) !important;
      background-color: white !important;
      color: $black;
      // width: 32% !important;
      border: 1px dashed !important;
    }
    .black-button-width {
      max-width: fit-content;
    }
    .meet-and-assist-description {
      font-size: 14px;
      color: $dark-2;
      padding-left: 5px;
      display: block;
      line-height: 27px !important;
    }
    .meet-and-assist-features {
      padding-left: 5px;
      padding-top: 2.5em;
      padding-bottom: 2em;

      div {
        background: url('/images/Tick_Icon.png');
        font-size: 14px;
        background-position: top 3px left 0px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 28px;
        margin-bottom: 4px;
      }
    }
    .meet-and-assist-confirm {
      line-height: 34px !important;
      margin-bottom: 0px !important;
    }
    .meet-and-assist-confirm-inner {
      padding-left: 5px !important;
      padding-top: 2em !important;
      padding-bottom: 2em !important;
    }
    .meet-assist-payment-block {
      font-size: 15px;
      margin-top: 1em;
      display: flex;
      justify-content: space-between;
    }

    .status-paid {
      font-size: 13px;
      color: $success;
    }
    .status-unpaid {
      font-size: 13px;
      color: $dark;
    }
  }

  .booking-summary-section {
    padding-left: 6px;
    padding-right: 6px;
  }
  .driver-actions--container {
    margin-top: 20px;
    .nav-tabs {
      background: rgba(139, 149, 158, 0.15);
      padding: 7px 15px;
      border-radius: 4px;
      font-size: 13px;
      margin-bottom: 25px;
      .nav-link {
        color: $dark;
        border: none;
        border-radius: 3px;
        &:hover {
          color: $dark-2;
        }
        &.active {
          background-color: #fff;
          font-weight: bold;
          color: $black;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        }
        &.disabled {
          pointer-events: inherit;
        }
      }

      .redTab {
        color: red !important;
      }

      .reload-button {
        display: flex;
        align-items: flex-end;
        font-size: 10px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 3px;

          &.reloading {
            -webkit-animation: spin 2s linear infinite;
            -moz-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
          }
        }
      }
    }

    .booking-operation-container {
      padding: 0px 10px;
      .booking-operation-item {
        padding: 10px 0px 10px 20px;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &--status {
          flex-basis: 50%;
          &:before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: $brown;
            position: absolute;
            left: 0px;
            top: 15px;
          }

          &--label {
            font-size: 14px;
            color: $black;
          }

          &--detail {
            font-size: 12px;
            color: $dark;
            margin-top: -12px;

            .sms-status {
              .received {
                position: relative;
                &:before {
                  display: block;
                  background-image: url(/images/icons/check_2.png);
                  background-size: cover;
                  width: 12px;
                  height: 9px;
                  content: '';
                  right: -26px;
                  top: -1px;
                  position: absolute;
                }
              }
            }
          }
        }

        &--time {
          flex-basis: 35%;
          text-align: right;
          padding-right: 10px;
          &--diff {
            font-size: 12px;
            color: $dark-3;
            font-weight: bold;
          }
          &--detail {
            font-size: 12px;
            color: $dark;
            margin-top: -12px;
          }
        }

        &--location {
          flex-basis: 30%;
          padding: 0 5px;

          .location--container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            .map--container {
              position: relative;
              height: 100%;
            }
            .view-location {
              text-align: right;
              cursor: pointer;

              img {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }

  .booking-main-section {
    margin-left: 10px;
    margin-top: 2.2em;

    .events-header {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
      height: 20px;
      min-height: 43px;
      .section-header {
        margin: 0;
      }
    }
  }
  .payment-link-main-section {
    margin-left: 10px;
    margin-top: 1.5em;
    @media (max-width: 768px) {
      margin-left: 0px;
      margin-right: 0.625em;
      margin-top: 0em;
    }
  }

  .booking-event-section {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    min-height: 150px;
    position: relative;
    width: calc(100% - 20px);
    .booking-form-wrap {
      max-width: 900px;
      // max-width: none;
    }

    .events-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      height: 20px;
      min-height: 43px;
      .section-header {
        margin: 0;
      }
      .section-sub-header {
        margin: 0;
      }

      .cancel-booking {
        color: $red;

        &:hover {
          text-decoration: none;
          color: $red;
        }
      }
    }
    .events-div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-size: 15px;
      background-color: #fafafa;
      padding: 24px;
      border-radius: 4px;

      .events-near-me-card {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 16px;
        background-color: $light-3;
        border-radius: 2px;
        font-size: 15px;
        color: $black;
        margin-top: 10px;
        margin-bottom: 5px;
        @include media-breakpoint-down(md) {
          gap: 5px;
          align-items: flex-start;
        }
        .text {
          flex: 1;
        }
        .duration {
          font-size: 14px;
          color: $dark-grey-2;
        }
      }
      .sep {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 3px;
        background-color: $dark;
        vertical-align: middle;
        margin-left: 4px;
        margin-bottom: 2px;
        margin-right: 1px;
      }

      .event-details {
        display: flex;
        gap: 1.5rem;
        margin-top: 4px;

        span {
          font-size: 14px;
          color: $dark-grey-2;
        }

        .temp {
          width: 20px;
          height: 20px;
          right: 2px;
          position: relative;
          bottom: 3px;
        }

        .traffic-status {
          display: flex;
          align-items: baseline;
        }

        .traffic {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-left: 5px;
        }

        .heavy-traffic {
          background-color: $danger;
        }

        .moderate-traffic {
          background-color: $warning;
        }

        .normal-traffic {
          background-color: $success;
        }
      }
    }
    .messages-list {
      //overflow-y: auto;
      height: calc(100vh - 440px);

      .message-header {
        line-height: 36px;
      }

      .vehicle-card {
        width: unset;
        // border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
    .message-block-mobile {
      padding-right: 1% !important;
    }
    .message-block {
      max-width: 100%;
      margin-bottom: 15px;
      margin-left: 0;
      border-top: 1px solid rgba($black, 0.05);
      padding-right: 15%;

      &:hover {
        text-decoration: none;
      }

      .message-header {
        margin-top: 10px;
        display: flex;
        align-items: center;
        width: 32vw;
        max-width: 100%;
        flex-direction: row;

        .event-type-indicator {
          width: 40px;
          display: flex;
          justify-content: center;
        }

        p {
          padding: 0;
          margin: 0;

          &.date-row {
            padding-left: 10px;
            font-size: 12px;
            color: #8b959e;
          }
        }
        pre {
          padding: 0;
          margin: 0;

          &.date-row {
            padding-left: 10px;
            font-size: 12px;
            color: #8b959e;
          }
        }
      }

      .message-content {
        padding-left: 50px;

        p {
          padding: 0;
          margin: 0;
          font-size: 15px;

          &.text-content {
            font-size: 15px;
            color: #0c0c0c;
            word-break: break-word;

            img {
              width: 14px;
              height: auto;
              margin: 0 6px 0 0;
              margin-bottom: 5px;
            }
          }

          &.date-row {
            font-size: 8px;
            color: #8b959e;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .download-link {
          color: #a79779;
          font-size: 10px;

          .pdf-icon {
            width: 11px;
            height: 11px;
            margin-right: 7px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }

      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid rgba($black, 0.1);
        overflow: hidden;

        &.user-online {
          border: 1px solid $success;
        }

        img {
          display: block;
          width: 34px;
          height: auto;
          object-fit: cover;
          object-position: center center;
        }
      }

      .client-indicator {
        background: rgba(139, 149, 158, 0.3);
        height: 10px;
        width: 10px;
        border-radius: 10px;
      }

      .vehicle-wrap {
        order: 2;
        color: rgba($dark-4, 0.6);
        font-size: 8px;
      }
    }

    .input-message-block {
      bottom: 10px;
      width: 100%;
      background: white;

      .input-message-area {
        display: flex;
        align-items: flex-start;
        width: auto;
        max-width: none;
        padding: 0;

        .logo {
          width: 45px;
          min-width: 45px;
          display: flex;
          justify-content: center;
          margin-right: 15px;
        }

        .input-message-wrap {
          width: calc(100% - 100px);
          margin: 0;
          padding-top: 2px;
          position: relative;
          flex-grow: 1;

          .form-group {
            margin-bottom: 0px;
          }

          .chat-paperclip {
            position: absolute;
            background: transparent;
            left: 5px;

            .custom-file-label {
              background: transparent;
            }
          }

          .toolbar-buttons {
            position: absolute;
            left: 5px;
            top: 7px;
            display: flex;
            align-items: center;

            .toolbar-btn {
              &.car-btn {
                width: 24px;
                height: 24px;
                margin-right: 22px;
                z-index: 1;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          textarea {
            padding-left: 40px !important;
            box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            border-color: rgba($black, 0.1);
            font-size: 15px !important;
          }

          textarea::placeholder {
            color: #000000;
            opacity: 0.4;
          }

          textarea:-ms-input-placeholder {
            color: #000000;
            opacity: 0.4;
          }

          textarea::-ms-input-placeholder {
            color: #000000;
            opacity: 0.4;
          }

          .placeholder-icon-wrap {
            position: absolute;
            z-index: 1;
            top: 11px;
            background: transparent;

            .placeholder-icon {
              width: 16px;
              height: 16px;
              object-fit: contain;
            }
          }
        }
      }
    }

    .preview-files-block {
      margin-top: 20px;

      .file-preview {
        justify-content: flex-start;
      }

      .image-preview-label {
        max-width: unset;
        width: 100%;
        overflow: hidden;
        margin: 0 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
      }

      .remove-file-btn {
        display: block;
        color: #a79779;

        &:hover {
          text-decoration: none;
          color: #a79779;
        }

        img {
          width: 10px;
          height: 10px;
        }
      }
    }

    .send-btn-wrap {
      overflow: hidden;
      padding: 0;

      a {
        padding: 6px 6px;

        img {
          width: 26px;
          height: 20px;
        }
      }
    }

    .user-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid rgba(12, 12, 12, 0.1);
      overflow: hidden;
    }

    .user-logo {
      //width: 36px;
      //height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .default-logo {
      height: 34px;
      width: 34px;
      min-width: 34px;
      border-radius: 50%;
      //border-top: 1px solid rgba($black, 0.05);
      //background-color: $white;
      background: rgba($dark-grey, 0.25);

      img {
        //width: 26px;
        //height: 26px;
      }
    }
  }
}

.vip-section {
  display: flex;
  justify-content: space-between;

  img {
    width: 16px;
    height: 14px;
  }
}

.vip-passenger-img {
  width: 17px;
  height: 15px;
  margin-left: 0.5rem;
  margin-bottom: 4px;
}

.rolzo-first-star-icon {
  width: 14.5px;
  height: 15px;
  margin-left: 0.5rem;
  margin-bottom: 4px;
}

.follow-up-img {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  margin-bottom: 4px;
}

.booking-total {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total-title {
    font-size: 20px;
  }
  .total-value {
    font-size: 25px;
  }
  .total-value-confirm-booking {
    font-size: 20px !important;
  }
  .total-value-confirm-booking-mobile {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .total-title-confirm-booking-mobile {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .total-title-new-mobile {
    font-size: 16px !important;
    color: $dark-2;
  }
  .total-value-new-mobile {
    font-size: 16px !important;
    color: $black;
  }
  .total-title-new {
    font-size: 18px !important;
    color: $dark-2;
  }
  .total-value-new {
    font-size: 18px;
  }
}
.discount-value {
  text-align: end;
  font-size: 12px;
  color: $dark;
}
.exclusive-benefits-font {
  font-size: 1rem !important;
  color: $dark-2;
}
.total-wrap {
  flex-direction: column;

  .total-title {
    font-size: 15px;
    color: #8b959e;
  }
  .dispatch-title {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: #8b959e;
    margin-bottom: 0.3rem;
    line-height: 12px;
    margin-top: 5px;
  }
  .dispatch-text {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 1;
    background-color: rgba($dark-grey, 0.15);
    padding: 12px;

    a {
      font-size: 14px !important;
    }

    a:hover {
      text-decoration: none;
    }
  }
  .dispatch-text-mismatch {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 1;
    background-color: rgba($dark-grey, 0.15);
    padding: 12px;
    border: 1px solid $danger;
  }
  .mismatch-text {
    color: $danger;
    font-size: 12px;
    float: right;
  }
  .dispatch-textarea {
    font-size: 14px;
    margin-bottom: 1;
    background-color: rgba($dark-grey, 0.15);
    padding: 13px;
  }
  .total-link {
    color: $brown-active;
    font-size: 10px;
  }
  .total-title-benefit {
    font-size: 11px;
    color: #8b959e;
  }
}
.total-wrap-half {
  flex-direction: column;
  width: 50%;
  .total-title {
    font-size: 15px;
    color: #8b959e;
  }
  .total-title-benefit {
    font-size: 11px;
    color: #8b959e;
  }
}

.payment-section {
  display: flex;
  justify-content: space-between;
}

.success-block {
  min-width: 163px;

  .confirmation-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 15px;
  }
  .download-link {
    color: #a79779;
    font-size: 14px;

    .pdf-icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-bottom: 7.5px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.success-payment-block {
  min-width: 163px;
  justify-content: end;
  display: grid;
  .success-payment-msg {
    color: green;
    font-size: 14px;
    text-align: right;
  }
  .danger-payment-msg {
    color: red;
    font-size: 14px;
    text-align: right;
  }
  .black-payment-msg {
    color: $black;
    font-size: 14px;
    text-align: right;
  }

  .download-link {
    color: #a79779;
    font-size: 14px;

    .pdf-icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-bottom: 7.5px;
    }

    &:hover {
      text-decoration: none;
    }
  }
  .invoice-link {
    padding-right: 5.7rem;
  }
}

.authorized-driver-files {
  .download-link {
    color: #a79779;
    font-size: 14px;
    .pdf-icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.success-charges-payment {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(120, 128, 134, 0.5) !important;
  height: 10%;
  opacity: 0.5;
}

.charge-form {
  .is-invalid {
    padding-right: 5px;
  }
}
.driver-card-pb-0 {
  padding: 0px !important;
  margin-top: 0px !important;
  .sub-header {
    margin-bottom: 0px !important;
  }
}
.driver-card {
  padding: 20px;
  margin-top: 50px;
}

.booking-form-wrap {
  max-width: 700px;
  // max-width: none;
  margin-top: 10px;

  .distance-duration {
    margin-top: 1.2rem;
    //margin-left: 20px;
    font-size: 14px;
    color: $dark-grey;
  }
  .distance-duration-new {
    margin-top: 15px;
    font-size: 14px;
    color: $dark-grey;
  }

  .cancelable {
    color: $red;
    text-transform: initial;
    margin-top: -9px;
    font-size: 12px;
  }

  .white-field-input {
    input.custom-datepicker-control {
      background-color: $white;
      border: 0.5px solid rgba(#8c969f, 0.3);

      &:hover,
      &:active {
        border: 0.5px solid rgba(#8c969f, 1) !important;
      }
    }

    .react-select-container .react-select__control {
      background-color: white;
      border: 0.5px solid rgba(#8c969f, 0.3);

      &:hover,
      &:active {
        border: 0.5px solid rgba(#8c969f, 1) !important;
      }
    }
  }

  .custom-file-label {
    z-index: unset !important;
  }

  .new-booking-wrap {
    background: #fafafa;
    padding: 2em;
    padding-right: 2.1em;
    margin-right: 2em;
    border-radius: 2px;
  }

  .b2c-booking-wrap {
    background: #fafafa;
    padding: 2em;
    padding-right: 2.1em;
    border-radius: 2px;
  }

  .results-wrap {
    margin-right: 2em;
    padding-bottom: 10rem;
    margin-top: 1rem;
  }

  .reduce-field-width {
    width: 90% !important;
  }
  .new-booking-wrap {
    padding: 30px 30px 40px 47px;
    .new-booking-title {
      margin-left: 0;
    }
    .itinerary-container {
      position: relative;
    }
    .itinerary-indicator {
      position: absolute;
      left: -19px;
    }
    .remove-button-stops-container,
    .remove-button-stops.absolute {
      position: absolute;
      right: -23px;
      top: 50%;
      transform: translateY(-50%);
      left: unset;
    }
    .form-row {
      margin-inline: 0;
    }
  }
  .row-without-stops-start {
    width: 100%;
    padding-right: 23px;
  }
  .row-without-stops {
    width: 100%;
  }
  .row-with-stops {
    width: 100%;
  }

  .line-break-daily {
    margin-top: 2.1rem;
    margin-bottom: 3rem;
  }

  .new-booking-container {
    margin-bottom: 1.5em;

    .new-booking-label {
      padding: 0.25rem 1.25rem;
      background-color: rgba($dark, 0.1);
      border-radius: 2px;
      font-size: 14px;
      font-weight: 800;
      color: black;
      width: fit-content;
      margin-bottom: 2rem;
    }

    .new-booking-title {
      font-size: 24px;
      margin-left: 22px;
      margin-bottom: 0 !important;
    }

    .b2c-booking-title {
      font-size: 20px;
      margin-left: 22px;
      margin-bottom: 0 !important;
    }

    .selected {
      border-bottom: 1px solid black;
      padding-bottom: 4px;
    }
    .not-selected {
      color: rgba(139, 149, 158, 0.5);
      cursor: pointer;
    }
  }
  .itinerary-container {
    .itinerary-title {
      font-size: 20px;
    }

    .itinerary-location-container {
      display: flex;
      column-gap: 1em;

      .itinerary-indicator {
        margin-top: 2.5em;
        display: flex;
        flex-direction: column;

        .circle-empty {
          height: 8px;
          width: 8px;
          border: 1.5px solid #8b959e;
          border-radius: 50%;
          display: inline-block;
          z-index: 0;
          background-color: white;
        }
        .circle-filled-pu {
          height: 8px;
          width: 8px;
          background-color: #8c969f;
          border-radius: 50%;
          display: inline-block;
        }
        .circle-filled {
          height: 8px;
          width: 8px;
          background-color: $black;
          border-radius: 50%;
          display: inline-block;
          z-index: 0;
        }
        .dotted-container {
          position: relative;
          width: 8px;
          height: 75px;
          overflow: hidden;
        }
        .dotted-container-stop-last {
          position: relative;
          width: 8px;
          height: 193px;
          overflow: hidden;
        }
        .dotted-container-stop {
          position: relative;
          width: 8px;
          height: 158px;
          overflow: hidden;
        }
        .dotted-container-last {
          position: relative;
          width: 8px;
          height: 110px;
          overflow: hidden;
        }
        .dotted-line {
          transform: rotate(90deg);
          position: absolute;
          top: -9999px;
          left: -9999px;
          right: -9999px;
          bottom: -9999px;
          margin: auto;
        }
      }

      .itinerary-fields {
        width: 100%;
      }
    }
  }
}

.remove-button-preference {
  position: relative;
  top: 3.5px;
  left: 3px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
  cursor: pointer;
}
.remove-button-stops {
  position: relative;
  top: 30.5px;
  left: 3px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
  cursor: pointer;
}

.remove-button-stops-details {
  position: relative;
  top: 30.5px;
  right: 5px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
  cursor: pointer;
}

.override-max-width {
  max-width: none !important;
}
#search-container {
  overflow-x: none;
}
#search-container-rental {
  width: calc(100vw - 101px - 55.7%);
  padding-left: 2em;
}
#search-container::-webkit-scrollbar {
  width: 10px;
}

#search-container::-webkit-scrollbar-track {
  border: 1px solid rgba(139, 149, 158, 0.3);
  border-top: none;
  border-bottom: none;
}

#search-container::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: rgba(139, 149, 158, 0.3) !important;
  border-radius: 5px;
}
.bottom-stepper {
  display: flex;
  position: sticky;
  bottom: 0;
  border-top: 1px solid rgba($dark, 0.3);
  padding: 0.75rem 2rem;
  // this isn't responsive, change
  margin-left: -4rem;
  background-color: #ffffff;
  z-index: 1;

  .stepper-btn {
    width: 200px;
    padding: 0 !important;
  }
}

.bottom-stepper-cont {
  display: flex;
  position: sticky;
  bottom: 0;
  margin-left: -4rem;
  width: calc(100vw - 101px);
  border-top: 1px solid rgba(139, 149, 158, 0.3);
  background-color: #ffffff;
}

.bottom-stepper-div {
  display: flex;
  padding: 0.75rem 2rem;
  width: calc(100vw - 101px - 49%);

  .stepper-btn {
    width: 200px;
    padding: 0 !important;
  }
}

.booking-form-wrap-payment {
  // max-width: 700px;
  max-width: none;
  margin-top: 10px;

  .distance-duration {
    margin-top: 1.2rem;
    //margin-left: 20px;
    font-size: 14px;
    color: $dark-grey;
  }
  .distance-duration-new {
    margin-top: 5px;
    //margin-left: 20px;
    font-size: 14px;
    color: $dark-grey;
  }

  .cancelable {
    color: $red;
    text-transform: initial;
    margin-top: -9px;
    font-size: 12px;
  }

  .custom-file-label {
    z-index: unset !important;
  }
}
.stop-map-marker {
  width: 10px;
  height: 10px;
  background-color: #8c969f;
  border-radius: 5px;
  color: white;
}
.booking-choose-car-title {
  // margin-top: 40px !important;
}
.booking-empty-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 45px;
  border-radius: 2px;
  box-shadow: $card-shadow;

  h5 {
    margin-bottom: 14px;
    font-size: 20px;
    color: $dark-2;
    font-weight: normal;
  }

  .empty-description {
    font-size: 15px;
    font-weight: 400;
    color: $dark-grey;
    white-space: pre-wrap;
  }

  .chat-btn,
  .float-btn {
    position: static;
    flex-shrink: 0;
    margin-left: 10px;
    width: 70px;
    height: 70px;
  }
}

.flight-preview {
  display: flex;
  justify-content: space-between;

  .flight-preview-form {
    width: 50%;
  }
}

.invoicing-mode {
  float: right;
  margin-top: -25px;
  padding: 5px;
  border-radius: 3px;
  color: gray;
  margin-right: 34px;
  font-size: 12px;
}

.cancel-booking-modal {
  .modal-dialog {
    // width: 750px;
  }

  .modal-header {
    border-bottom: none !important;

    .modal-title {
      width: 100%;

      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        margin-right: 2%;
        margin-top: 2%;

        &:hover,
        &:focus {
          outline: none;
        }

        img {
          width: 100%;
          height: 100%;
          outline: none;
        }
      }
    }

    padding-bottom: 0px;
  }

  .modal-body {
    //padding-top: 0px;
  }

  .title {
    display: flex;
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
    margin-top: 2%;
    padding-bottom: 2%;
    justify-content: center;
  }
  .modal-title-delete {
    display: flex;
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
    margin-top: 2%;
    padding-bottom: 1%;
    justify-content: center;
  }
  .billing-title {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: $dark-grey;
    text-transform: uppercase;
    margin-left: 0.9rem !important;
    margin-top: 1.5rem !important;
  }
  .billing-type {
    font-weight: 400;
    font-size: 14px !important;
    color: $black;
    margin-left: 0.9rem !important;
    margin-top: 0.5rem !important;
  }
  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 80px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }

  .modal-description {
    font-size: 14px;
    color: $dark-grey-2;
    text-align: center;
  }
  .modal-description-delete {
    font-size: 14px;
    color: $dark-grey-2;
    text-align: center;
    width: 57%;
    margin: 0 auto;
  }
  .SharedBooking-modal-description {
    font-size: 15px;
    color: $dark-grey;
    text-align: center;
  }
  .refund-modal-description {
    font-size: 20px;
    color: $black;
    text-align: center;
    padding-right: 11rem;
    padding-left: 11rem;
    line-height: 40px;
  }
  .payout-modal-description {
    font-size: 14px;
    color: $dark-grey;
    text-align: left;
  }
  .payout-modal-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 10rem;
  }
}
.share-booking-modal {
  .modal-dialog {
    // width: 750px;
  }

  .modal-header {
    border-bottom: none !important;
    padding: 2rem;

    .modal-title {
      width: 100%;
      margin-left: 0.5rem !important;
      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        float: right;
        height: fit-content;
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .btn-link-mobile {
        float: right;
        margin-right: 20px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
    }

    padding-bottom: 0px;
  }

  .modal-body {
    padding: 0.5rem 2rem 2.5rem 2rem;

    .sub-header {
      margin-left: 0.5rem !important;
      font-size: 18px;
    }
  }

  .title {
    font-size: 20px;
    color: $black;
    display: inline-block;
    font-weight: 400;
  }

  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 100px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }

  .modal-description {
    font-size: 15px;
    color: $dark-grey-2;
  }
}
.dispatch-booking-modal {
  div {
    max-width: 900px !important;
  }
  .modal-dialog {
    // width: 750px;
  }

  .modal-header {
    border-bottom: none !important;

    .modal-title {
      width: 100%;
      margin-left: 0.5rem !important;
      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        float: right;
        margin-right: 35px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
      .btn-link-mobile {
        float: right;
        margin-right: 20px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
    }

    padding-bottom: 0px;
  }

  .modal-body {
    //padding-top: 0px;
    .sub-header {
      margin-left: 0.5rem !important;
    }
  }

  .title {
    font-size: 20px;
    color: $black;
    display: inline-block;
    font-weight: 400;
    margin-top: 2%;
  }

  .dispatch-title {
    font-size: 18px;
    color: $black;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
  }

  .dispatch-subtitle {
    font-size: 14px;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
    color: $dark-grey;
    position: absolute;
  }

  .dispatch-form-title {
    font-size: 16px;
    padding-left: 24px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
    position: absolute;
  }

  .add-partner-submit {
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 9px;
  }

  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 100px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }

  .modal-description {
    font-size: 15px;
    color: $dark-grey-2;
  }
}

.outsourced-rate-modal {
  div {
    max-width: 736px !important;
    max-height: 312px !important;
  }

  .modal-content {
    padding: 2rem;
  }
  .modal-header {
    border-bottom: none !important;
    padding: 0px;

    .modal-title {
      width: 100%;
      margin-left: 0.5rem !important;
      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        float: right;
        margin-right: 35px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
      .btn-link-mobile {
        float: right;
        margin-right: 20px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
    }
  }

  .form-group {
    width: 213.67px !important;
  }
  .modal-body {
    padding: 0px;
    .sub-header {
      margin-left: 0.5rem !important;
    }
  }

  .title {
    font-size: 20px;
    color: $black;
    display: inline-block;
    font-weight: 400;
    margin-top: 2%;
  }

  .dispatch-title {
    font-size: 20px;
    color: $black;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
  }

  .dispatch-subtitle {
    font-size: 14px;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
    color: $dark-grey;
    position: absolute;
  }

  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 100px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }

  .modal-description {
    font-size: 15px;
    color: $dark-grey-2;
  }
}
.manual-partner-modal {
  div {
    max-width: 540px !important;
    max-height: 350px !important;
  }

  .modal-content {
    padding: 2rem;
  }

  .modal-description {
    font-size: 15px;
    color: $dark-grey-2;
  }

  .modal-header {
    border-bottom: none !important;
    padding: 0px;

    .modal-title {
      width: 100%;
      margin-left: 0.5rem !important;
      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        float: right;
        margin-right: 35px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
      .btn-link-mobile {
        float: right;
        margin-right: 20px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
    }
  }
  .modal-body {
    padding: 0px;
    .sub-header {
      margin-left: 0.5rem !important;
    }
  }
  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 100px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }

  // .form-group {
  //   width: 213.67px !important;
  // }

  .title {
    font-size: 18px;
    color: $black;
    display: inline-block;
    font-weight: 400;
    margin-top: 2%;
  }

  .dispatch-title {
    font-size: 20px;
    color: $black;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
    margin-bottom: 2rem;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0.75%;
  }

  .dispatch-subtitle {
    font-size: 14px;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
    color: $dark-grey;
    position: absolute;
  }

  .form-check-box {
    .row {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-bottom: 0.5rem;
      }
    }
  }

  .custom-checkbox .custom-control-label {
    font-weight: normal;
    font-size: 14px !important;
    color: #0c0c0c;
    padding-top: 0.15rem;

    .status-paid {
      margin-left: 0.3rem;
      font-size: 12px !important;
      color: $success;
      padding: 0px;
    }
    .status-unpaid {
      margin-left: 0.3rem;
      font-size: 12px !important;
      color: $dark;
      padding: 0px;
    }
  }

  .custom-radio {
    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &:after {
            border: none;
          }
        }
      }
    }
  }
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          border: 1px solid $black;
        }
      }
    }
  }
}
.charges-partner-modal {
  div {
    max-width: 735px !important;
  }
  .modal-dialog {
    // width: 750px;
  }

  .modal-header {
    border-bottom: none !important;

    .modal-title {
      width: 100%;
      margin-left: 0.5rem !important;
      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        float: right;
        margin-right: 35px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
      .btn-link-mobile {
        float: right;
        margin-right: 20px;
        margin-top: 2%;
        img {
          width: 14px;
        }
      }
    }

    padding-bottom: 0px;
  }

  .modal-body {
    //padding-top: 0px;
    .sub-header {
      margin-left: 0.5rem !important;
    }
  }

  .title {
    font-size: 20px;
    color: $black;
    display: inline-block;
    font-weight: 400;
    margin-top: 2%;
  }

  .modal-description {
    font-size: 15px;
    color: $dark-grey-2;
  }

  .dispatch-title {
    font-size: 18px;
    color: $black;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
  }

  .dispatch-subtitle {
    font-size: 14px;
    padding-left: 39px;
    display: inline-block;
    font-weight: 400;
    padding-top: 2.4%;
    color: $dark-grey;
    position: absolute;
  }

  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 100px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }
}

.add-max-width-search {
  max-width: calc((100vw - 101px) - 70%);
}
.add-max-width-map {
  max-width: calc((100vw - 101px) - 63%);
}

.shareModalList {
  height: 45vh;
  overflow-y: scroll;
}
.vehicleModalList {
  height: 50vh;
  overflow-y: scroll;
}
.dispatch-search-field {
  padding-left: 5%;
}
.shareDispatchList {
  height: 42.9vh;
  overflow-y: scroll;
}
.shareDispatchList-partner {
  border-bottom: 1px solid rgba($dark-grey, 0.3);
  height: 42.9vh;
  overflow-y: scroll;
}
.sub-header-dispatch {
  font-size: 16.5px;
  font-weight: bold;
  margin-bottom: 50px;
}
.check-box-dispatch {
  margin-right: 7%;
}
.dispatch-info {
  :first-child {
    font-weight: bolder;
    color: $black;
  }
  p {
    margin-top: 0;
    margin-bottom: 5px;
    color: $dark-grey;
  }
}
.dispatch-grey-row {
  background-color: rgba($dark-grey, 0.2) !important;
}
.share-profile-info {
  margin-left: 60px;
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.share-profile-info-mobile {
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.share-profile-image {
  width: 45px;
  height: 45px;
  float: left;
}
.shareUserImage {
  float: left;
}
.share-mobile-field {
  font-size: 14px !important;
}
.share-button {
  float: right;
  button {
    margin-right: 5px;
  }
  .select-btn {
    height: 35px;
    min-width: 100px;
  }
  img {
    width: 20px;
  }
  .share-mobile-cross-img {
    margin-right: 8px !important;
    img {
      width: 14px !important;
    }
  }
  .share-desktop-cross-img {
    img {
      width: 14px !important;
    }
  }
  .share {
    margin-right: 25px;
  }
  .shared:hover,
  .shared:active {
    color: $black !important;
  }
}
.terms-button {
  display: flex;
  justify-content: flex-end;
  padding: 15px 40px;

  .btn {
    height: 40px;
    width: 150px;
  }
}
.badge {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 10px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.badge.badge-outlined.badge-size11 {
  font-size: 11px !important;
}

.badge.badge-outlined {
  background-color: transparent;
}
.badge.badge-outlined-none {
  background-color: transparent;
  border-color: transparent !important ;
  font-size: 12px !important;
}

.badge.badge-outlined.badge-default {
  border-color: #b0bec5;
  color: #b0bec5;
}

.badge.badge-outlined.badge-primary {
  border-color: #2196f3;
  color: #2196f3;
}

.badge.badge-outlined.badge-secondary {
  border-color: #323a45;
  color: #323a45;
}
.badge.badge-outlined.badge-global-card {
  border-color: #b0bec5;
  color: #323a45;
}
.badge.badge-outlined.badge-success,
.badge.badge-Confirmed,
.badge.badge-accepted,
.badge.badge-outlined.badge-confirmed {
  border-color: $success;
  color: $success;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($success, 0.1);
}
.badge.badge-outlined.badge-success,
.badge.badge-completed {
  border-color: $black;
  color: $black;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($black, 0.1);
}

// diff width values for cross-browser compatibility
.osm-map {
  width: -webkit-fill-available;
  width: -moz-available;
  height: calc(100vh - 59px);
  position: absolute;
  z-index: 4;
  overflow: hidden;
}

.map-label-container {
  position: fixed;
  z-index: 100;
  margin-left: 2em;
  margin-top: 2em;
}
.map-label {
  padding: 3px 15px;
  background-color: white;
  color: #8b959e;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 800;
  width: fit-content;
  position: sticky;
  z-index: 100;
  margin-bottom: 2px;
}
.map-label-selected {
  color: black !important;
  border: 1px solid black !important;
}

.leaflet-control-zoom {
  border: 1px solid #9e9e9e !important;
  border-radius: 2px !important;
}

.leaflet-touch .leaflet-bar a {
  width: 18px !important;
  height: 18px !important;
  line-height: 14px !important;
  font-size: 16px !important;
}

.badge.badge-outlined.badge-quote_in_progress,
.badge.badge-outlined.badge-is_request,
.badge.badge-outlined.badge-is_request,
.badge.badge-Request,
.badge.badge-outlined.badge-in_review,
.badge.badge-dispatched,
.badge.badge-outlined.badge-Pending {
  border-color: $warning;
  color: $warning;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($warning, 0.1);
}

.badge.badge-outlined.badge-change_request {
  border-color: $red-2;
  color: $red-2;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($red-2, 0.1);
}

.badge.badge-outlined.badge-quote_in_progress,
.badge.badge-outlined.badge-changes_sent {
  border-color: $blue;
  color: $blue;
  font-weight: 700;
  background-color: rgba($blue, 0.1);
  font-size: 11px;
}

.badge.badge-outlined.badge-is_request {
  border-color: $yellow;
  color: $yellow;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($yellow, 0.1);
}

.badge.badge-outlined.badge-info {
  border-color: #29b6f6;
  color: #29b6f6;
}
.badge.badge-unavailable {
  border-color: $dark-grey;
  color: $dark-grey;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($dark-grey, 0.1);
}

.badge.badge-new-booking {
  border-color: #f5a623;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #f5a623;
  color: white;
}

.badge.badge-outsourced-dispatch {
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
}
.badge.badge-accepted-dispatch {
  border-color: #417505;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #417505;
  color: white;
}

.badge.badge-cancelled-dispatch {
  border-color: $danger;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: $danger;
  color: white;
}

.badge.badge-expired-dispatch {
  border-color: $danger;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: $danger;
  color: white;
}

.badge.badge-change-request-dispatch {
  border-color: $red-2;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: $red-2;
  color: white;
}

.badge.badge-completed-dispatch {
  border-color: #000000;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #000000;
  color: white;
}

.badge.badge-outlined.badge-danger,
.badge.badge-outlined.badge-Cancelled,
.badge.badge-cancellation_requested_by_client,
.badge.badge-cancellation_accepted_by_agent,
.badge.badge-transaction_declined,
.badge.badge-Declined,
.badge.badge-not_dispatched,
.badge.badge-cancelled_by_agent,
.badge.badge-outlined.badge-quote_expired {
  border-color: $danger;
  color: $danger;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba($danger, 0.1);
}

.badge.badge-outlined.badge-important {
  color: $danger;
  background-color: rgba($danger, 0.1);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-shadow: 0.5px 0 $danger;
}

.user-avatar {
  text-align: center;

  .rolzo-logo {
    width: 20px !important;
    height: auto !important;
    display: inline !important;
    margin-top: 6px;
    vertical-align: initial;
  }
}

.custom-datepicker-calendar {
  .react-datepicker__day--disabled {
    &.react-datepicker__day--selected,
    &.react-datepicker__day--today {
      color: #ccc !important;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.sharedList {
  color: #0c0c0c !important;
  span {
    margin-right: 10px;
  }
  img {
    width: 15px;
    height: 15px;
    margin-top: -5px;
  }
}

.electic-icon {
  width: 18px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: baseline;
}

.unPaidBlock {
  background: url('/images/icons/refundIcons/icon-unpaid.png');
  background-position: top 0.8px left 0px;
  background-size: 15px 15px;
  font-weight: 700;
  background-repeat: no-repeat;
  padding-left: 25px;
  margin-bottom: 4px;
  color: $danger;
  font-size: 14px;
}
.paidBlock {
  background: url('/images/icons/refundIcons/icon-succeeded.png');
  background-position: top 0.8px left 0px;
  background-size: 16px 13px;
  font-weight: 700;
  background-repeat: no-repeat;
  padding-left: 26px;
  margin-bottom: 4px;
  color: $success;
  font-size: 14px;
}
.refundedBlock {
  background: url('/images/icons/refundIcons/icon-refunded.png');
  background-position: top 0.8px left 3px;
  // background-size: 19px 12px;
  background-size: 17px 13px;
  font-weight: 700;
  background-repeat: no-repeat;
  padding-left: 29px;
  margin-bottom: 4px;
  color: $black;
  font-size: 14px;
}
.dueDateMessage {
  color: $dark-grey;
  font-size: 14px;
  margin-left: 1.6rem;
}

.change-request-delete {
  position: absolute;
  right: -33px;
  top: 34px;

  img {
    height: 12px;
    cursor: pointer;
  }
}

.pos-rel {
  position: relative;
}

.add-mr {
  margin-right: 20px;
}

.textArea-border {
  background-color: $white !important;
  border-radius: 2px !important;
  border: 1px solid rgba($dark-grey, 0.1) !important;
  border-color: $brown !important;
  box-shadow: $default-shadow !important;
}
.textArea-shadow {
  background-color: $white !important;
  border-radius: none !important;
  border: none !important;
  // box-shadow: $default-shadow !important;
  //box-shadow: $default-shadow;
  box-shadow: $card-shadow !important;
  // box-shadow: 0px 10px 15px 0px rgba(#000000, 0.1) !important;
  min-height: 90px !important;
}
.dispatchState-cancelled,
.dispatchState-declined,
.dispatchState-No {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.dispatchState-quote_requested {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.dispatchState-dispatched {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  // background-color: $blue;
  background-color: $warning;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.dispatchState-accepted {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.dispatchState-completed {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $black;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.dispatchState-updated {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $warning;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.dispatchState-completed {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $black;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.chauffeur-yes {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.chauffeur-no {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red;
  color: white;
  padding: 0.4rem 0.45rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.chauffeur-received {
  font-size: 11px !important;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $warning;
  color: white;
  padding: 0.4rem 0.45rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.greeter-yes {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.4rem !important;
  text-transform: uppercase;
}
.greeter-no {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.4rem !important;
  text-transform: uppercase;
}
.alerts-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $red;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $light-2;
  color: $red;
  padding-top: 0.1rem;
}
.dispatch-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $black;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $light-2;
  color: $black;
  padding-top: 0.1rem;
}

.timer-red {
  white-space: nowrap;
  color: $red;
}
.timer-black {
  white-space: nowrap;
  color: $black;
}

.filters-booking-modal {
  border: 1px solid rgba($dark-grey, 0.3) !important;
  .modal-header {
    border-bottom: none !important;

    .modal-title {
      width: 100%;

      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        margin-right: 5%;
        margin-top: 3%;

        img {
          width: 60px;
        }
      }
    }

    padding-bottom: 0px;
  }

  .title {
    display: flex;
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
    margin-top: 2%;
    padding-bottom: 2%;
    justify-content: center;
  }
  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    background-color: $light;
    padding-bottom: 65px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }
}
.badge.badge-refunded-admin,
.badge.badge-not_dispatched-admin,
.badge.badge-declined-admin,
.badge.badge-cancelled_by_agent-admin,
.badge.badge-cancellation_requested_by_client-admin,
.badge.badge-cancellation_accepted_by_agent-admin,
.badge.badge-outlined.badge-quote_expired-admin,
.badge.badge-transaction_declined-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $danger;
  border-color: $danger;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-is_request-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $yellow;
  border-color: $yellow;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-change_request-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red-2;
  border-color: $red-2;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-partially_paid-admin,
.badge.badge-outlined.badge-refunded-admin,
.badge.badge-outlined.badge-quote_requested-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-not_paid-admin,
.badge.badge-dispatched-admin,
.badge.badge-outlined.badge-in_review-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $warning;
  border-color: $warning;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-paid-admin,
.badge.badge-outlined.badge-confirmed-admin,
.badge.badge-outlined.badge-accepted-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  border-color: $success;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-on_the_way-admin,
.badge.badge-outlined.badge-on_location-admin,
.badge.badge-outlined.badge-passenger_onboard-admin,
.badge.badge-outlined.badge-ten_mins_away-admin,
.badge.badge-outlined.badge-delivered-admin,
.badge.badge-outlined.badge-accepted-admin,
.badge.badge-outlined.badge-passenger_dropped_off-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  border-color: $success;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-not_on_the_way-admin,
.badge.badge-outlined.badge-not_on_location-admin,
.badge.badge-outlined.badge-not_onboard-admin,
.badge.badge-outlined.badge-not_delivered-admin,
.badge.badge-outlined.badge-not_dropped_off-admin,
.badge.badge-outlined.badge-not_accepted-admin,
.badge.badge-outlined.badge-not_assigned-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $danger;
  border-color: $danger;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-received-admin,
.badge.badge-outlined.badge-changed-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $warning;
  border-color: $warning;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-assigned-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $blue;
  border-color: $blue;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-cancelled-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $danger;
  border-color: $danger;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-completed-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $black;
  border-color: $black;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.badge.badge-unavailable-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $dark-grey;
  border-color: $dark-grey;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-quote_in_progress-admin,
.badge.badge-outlined.badge-changes_sent-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $blue;
  border-color: $blue;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.filter-btn {
  font-family: $font-family-base !important;
  position: relative;
  min-width: auto;
  max-width: fit-content !important;
  height: 30px !important;
  padding: 0.3rem;
  border: none;
  border-radius: 2px;
  background-color: $light-2;
  box-shadow: 0px 0px 4px 0px rgba(#000000, 0.2) !important;

  .btnTitle {
    font-size: 13px !important;
    display: inline-block;
    color: $black !important;
    font-weight: 950 !important;
    text-shadow: 0.3px 0 $black;
    margin-right: 0.2rem !important;
    padding-top: 0.2rem !important;
  }
  .btnValue {
    font-size: 13px !important;
    display: inline-block;
    color: rgba($black, 0.7) !important;
    // font-weight: 100 !important;
  }
}
.filter-close-btn {
  border-left: 0.5px solid rgba($dark-grey, 0.2);
}

.clear-filter-btn {
  font-family: $font-family-base !important;
  font-size: 13px !important;
  display: block;
  color: $red;
  text-shadow: 0.6px 0 $red;
  padding-bottom: 0.2rem;
  // font-weight: 950;
  &:hover {
    text-decoration: none;
    color: $red;
  }
}

.filter-btn-padding {
  padding-left: 1.9rem !important;
}

.badge.badge-outlined.badge-new-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $blue;
  border-color: $blue;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-active-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  border-color: $success;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-unverified-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #4a332d;
  border-color: #4a332d;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-gold-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d6af36;
  border-color: #d6af36;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-silver-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $dark-grey;
  border-color: $dark-grey;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-inactive-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red;
  border-color: $red;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-blacklisted-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $black;
  border-color: $black;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-backup-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $purple;
  border-color: $purple;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-declined-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $white;
  border-color: $black;
  color: $black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-exclusive-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $brown;
  border-color: $brown;
  color: $white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.admin-changes-logs {
  .message-block-mobile {
    padding-right: 1% !important;
  }

  .message-block {
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
    // border-top: 1px solid rgba($black, 0.05) !important;
    border-bottom: 1px solid rgba($black, 0.05);
    padding-right: 8%;

    &:hover {
      text-decoration: none;
    }
    &:first-child {
      border-top: none;
    }
    &:last-child {
      padding-bottom: 10px;
    }

    .message-header {
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 32vw;
      max-width: 100%;
      flex-direction: row;

      .event-type-indicator {
        width: 40px;
        display: flex;
        justify-content: center;
      }

      p {
        padding: 0;
        margin: 0;

        &.date-row {
          padding-left: 10px;
          font-size: 12px;
          color: #8b959e;
        }
      }
      pre {
        padding: 0;
        margin: 0;

        &.date-row {
          padding-left: 10px;
          font-size: 12px;
          color: #8b959e;
        }
      }
    }

    .message-content {
      padding-left: 50px;

      p {
        padding: 0;
        margin: 0;
        font-size: 15px;

        &.text-content {
          font-size: 15px;
          color: #0c0c0c;
          word-break: break-word;

          img {
            width: 14px;
            height: auto;
            margin: 0 6px 0 0;
            margin-bottom: 5px;
          }
        }

        &.date-row {
          font-size: 8px;
          color: #8b959e;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .download-link {
        color: #a79779;
        font-size: 10px;

        .pdf-icon {
          width: 11px;
          height: 11px;
          margin-right: 7px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .client-indicator {
      background: rgba(139, 149, 158, 0.3);
      height: 10px;
      width: 10px;
      border-radius: 10px;
    }
  }

  .client-indicator {
    background: rgba(139, 149, 158, 0.3);
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }
  .message-header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 32vw;
    max-width: 100%;
    flex-direction: row;
    line-height: 36px;

    .event-type-indicator {
      width: 40px;
      display: flex;
      justify-content: center;
    }

    p {
      padding: 0;
      margin: 0;

      &.date-row {
        padding-left: 10px;
        font-size: 12px;
        color: #8b959e;
      }
    }
    pre {
      padding: 0;
      margin: 0;

      &.date-row {
        padding-left: 10px;
        font-size: 12px;
        color: #8b959e;
      }
    }
  }

  .message-content {
    padding-left: 50px;

    p {
      padding: 0;
      margin: 0;
      font-size: 15px;

      &.text-content {
        font-size: 15px;
        color: #0c0c0c;
        word-break: break-word;

        img {
          width: 14px;
          height: auto;
          margin: 0 6px 0 0;
          margin-bottom: 5px;
        }
      }

      &.date-row {
        font-size: 8px;
        color: #8b959e;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .download-link {
      color: #a79779;
      font-size: 10px;

      .pdf-icon {
        width: 11px;
        height: 11px;
        margin-right: 7px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .close-icon {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.bookingChanges-header {
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100 !important;
  margin: 1.5em 0.3em 2.5em 0.3em !important;
  // padding: 0.3em, 0.3em, 1em, 0.3em !important;
}
.acceptedChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $success;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $success;
  color: $white;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem;
}
.declinedChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $red;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $red;
  color: $white;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem;
}

.not_openedChanges-number,
.openedChanges-number,
.unopenedChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $yellow;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $yellow;
  color: $white;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem;
}
.newChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $brown;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $brown;
  color: $white;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem;
}

.acceptedDispatch-number-tab,
.acceptedChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $success;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $success;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
}
.accepted-partner-number-tab {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $success;
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background: $success;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 1.3rem;
  font-weight: 900;
}

.notes-partner-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $brown;
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background: $brown;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 1.3rem;
  font-weight: 900;
}
.declinedDispatch-number-tab,
.declinedChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $red;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
  // margin-bottom: 0.1rem;
}

.dispatched-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $warning;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $warning;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
}
.completedDispatched-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $black;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
}

.not_openedChanges-number-tab,
.openedChanges-number-tab,
.unopenedChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $yellow;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $yellow;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
  // margin-bottom: 0.1rem;
}
.received-partner-number-tab {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $yellow;
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background: $yellow;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 1.3rem;
  font-weight: 900;
}
.newChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $brown;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $brown;
  color: $white;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
  // margin-bottom: 0.1rem;
}
.decline-offer-booking-modal {
  .modal-content {
    padding: 40px;
    padding-bottom: 48px;
  }
  .modal-header {
    border-bottom: none !important;
    padding: 0;

    .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px;

      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  .title {
    display: flex;
    font-size: 20px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400;
    margin-bottom: 36px;
    width: 67%;
  }

  .modal-body {
    padding: 0;
    padding-bottom: 36px;

    .modal-description {
      font-size: 15px;
    }
    .modal-options {
      margin-top: 5px;
    }
  }

  .modal-footer {
    border-top: none !important;
    justify-content: end !important;
    padding: 0;
  }
}
.client-commission-modal {
  .modal-content {
    width: 90%;
  }
  .modal-header {
    border-bottom: none !important;
    padding: 2rem 1rem;

    .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px;

      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  .modal-body {
    margin-bottom: 5em;
    padding: 0;
    padding-left: 13em;
    padding-right: 13em;

    .price-text {
      text-align: center;
      font-size: 25px;
    }
    .commission-text {
      text-align: center;
      font-size: 15px;
      color: $green;
    }
    .description-text {
      text-align: center;
      font-size: 15px;
      margin-top: 1.5em;
      color: $dark-grey-2;
    }
  }
}
.bank-deatil-modal {
  .modal-content {
    width: 548px;
  }
}
.fade.modal-backdrop.whats-new-backdrop.show {
  opacity: 0.6;
}

.whats-new-modal {
  .modal-size {
    width: 920px;
    max-width: 920px;
  }
  .modal-content {
    height: 100%;
    border-radius: 0.3rem;
    border: none !important;
    overflow-y: auto;
  }
  .modal-header {
    border-bottom: none !important;
    padding-top: 40px;
    padding-bottom: 0px;
    .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px;
      .close-btn-wrap {
        img {
          width: 12px;
          position: absolute;
          right: 40px;
          top: 40px;
        }
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-panel {
    display: flex;
    height: 460px;
    max-height: 460px;

    .modal-div {
      max-width: calc(100vw - 50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      .image {
        height: 100%;
      }
    }
  }

  .modal-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .whatsnew {
      color: $brown;
      font-size: 15px;
      margin-bottom: 12px;
      align-self: start;
      margin-left: 40px;
      margin-top: 45px;
    }

    .title {
      font-size: 24px;
      margin-bottom: 12px;
      align-self: start;
      margin-left: 40px;
      margin-right: 40px;
    }

    .description {
      font-size: 15px;
      color: $dark-grey-2;
      margin-bottom: 25px;
      word-wrap: break-word;
      align-self: start;
      margin-left: 40px;
      white-space: pre-line;
      margin-right: 45px;
    }

    .cta {
      display: flex;
      align-self: flex-end;
      margin-bottom: 70px;
      margin-right: 40px;

      a {
        padding: 0 2.3rem;
        font-size: 15px;
        padding-top: 8px;
      }
    }
  }
}
.com-popup-modal {
  .modal-size {
    width: 45rem;
    max-width: 45rem;
  }
  .modal-header {
    border-bottom: none !important;
    padding: 2rem 1rem;

    .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px;

      .close-btn-wrap {
        img {
          width: 12px;
          position: absolute;
          right: 40px;
          top: 35px;
        }
      }
    }
  }

  .modal-body {
    margin-bottom: 3rem;
    padding: 0;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    .title {
      font-size: 23px;
      margin-bottom: 10px;
      text-align: center;
    }

    .description {
      font-size: 14px;
      color: $dark-grey-2;
      width: 95%;
      margin-bottom: 1.5rem;
      word-wrap: break-word;
    }
  }
}

.terms-modal {
  .modal-size {
    width: 920px;
    max-width: 920px;
  }
  .modal-content {
    height: 100%;
    border-radius: 0.3rem;
    border: none !important;
  }
  .modal-header {
    border-bottom: none !important;
    padding-top: 40px;
    padding-bottom: 0px;
    .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px;
      .close-btn-wrap {
        img {
          width: 12px;
          position: absolute;
          right: 40px;
          top: 40px;
        }
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-panel {
    display: flex;

    .modal-div {
      max-width: calc(100vw - 50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      .image {
        height: 100%;
      }
    }
  }

  .modal-container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .title {
      font-size: 24px;
      margin-bottom: 12px;
      align-self: start;
      padding-left: 40px;
      padding-right: 40px;
    }

    .description {
      font-size: 15px;
      color: $dark-grey-2;
      margin-bottom: 10px;
      word-wrap: break-word;
      align-self: start;
      white-space: pre-line;
      padding-left: 40px;
      padding-right: 40px;
    }

    .terms {
      padding-top: 20px;
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-left: 40px;
      padding-right: 20px;
    }

    .terms::-webkit-scrollbar {
      width: 8px;
    }

    .terms::-webkit-scrollbar-thumb {
      background-color: rgba(139, 149, 158, 0.5);
      border-radius: 10px;
      height: 2px;
      width: 6px;
    }

    .terms::-webkit-scrollbar-track {
      background-color: #fafafa;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .text-normal {
      font-size: 14px;
    }

    .text-bold {
      font-size: 14px;
      font-weight: 800 !important;
      text-shadow: 0 0 $black;
    }

    .cta {
      display: flex;
      align-self: flex-end;
      margin-bottom: 70px;
      margin-right: 40px;

      a {
        padding: 0 2.3rem;
        font-size: 15px;
        padding-top: 8px;
      }
    }
  }
}

.terms-modal-mobile {
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .content-container-terms {
    background-color: white;
    width: 100%;
    height: 100%;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    align-items: center;
  }

  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close {
    height: 12px;
    width: 12px;
  }

  .popup-content {
    width: 100%;
    height: 100%;
    padding: 16px;
    padding-top: 250px;
  }

  .popup-title {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .popup-text {
    font-size: 15px;
    text-align: left;
    width: 100%;
    margin-top: 20px;
  }

  .bottom-container {
    position: absolute;
    bottom: 0;
    justify-content: center;
    width: 100%;
    align-items: center;
    border-top: 1.5px solid rgba(139, 149, 158, 0.25);
    padding-top: 20px;
    background-color: #ffffff;
  }

  .text-normal {
    font-size: 14px;
  }

  .text-bold {
    font-size: 14px;
    font-weight: 800 !important;
    text-shadow: 0 0 $black;
  }

  .terms {
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    padding-bottom: 150px;
  }
}

.modal-content {
  border: 1px solid rgba($dark-grey, 0.3) !important;
  // padding: 40px 40px 48px 40px;
}
.upload-photo-modal {
  .modal-content {
    width: 70%;
  }
  .modal-header {
    border-bottom: none !important;
    padding: 2rem 1rem;

    .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px;

      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  .modal-body {
    margin-bottom: 5em;
    padding: 0;
    padding-left: 2em;
    padding-right: 2em;

    button {
      float: right;
    }
  }
}

.chargeCheckBox label {
  color: $dark-grey !important;
  padding-top: 0.2rem !important;
  line-height: 12px;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: pre;
}

.important-booking-img {
  width: 0.875rem; //14px
  height: 0.906rem; //14.5px
  // margin-top: 0.1rem;
  margin-left: 0.5rem; //8px
  margin-bottom: 4px;
}
.important-booking-img-chat {
  width: 0.875rem; //14px
  height: 0.906rem; //14.5px
  // margin-top: 0.1rem;
  margin-left: 0.5rem; //8px
  // margin-bottom: 4px;
}

.important-booking-badge {
  width: 1.313rem; //21px
  height: 1.313rem; //21px
  margin-top: 0.1rem;
  margin-left: 10px;
  margin-bottom: 4px;
}

.tragectory-img {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-bottom: 4px;
}

.unsort-icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  cursor: pointer;
}

.sort-icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-bottom: 4px;
  cursor: pointer;
  &.ascending {
    transform: rotate(-90deg);
  }
  &.descending {
    transform: rotate(90deg);
  }
}

.badge.badge-outlined.badge-paid-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  border-color: $success;
  color: white;
  padding: 0.3rem 1rem 0.3rem 1rem;
  text-transform: uppercase;
  text-align: center;
}

.badge.badge-outlined.badge-inactive-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red !important;
  border-color: $red !important;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-new-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  border-color: $success;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-semi_active-user,
.badge.badge-outlined.badge-early_bird-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $yellow;
  border-color: $yellow;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-first_time-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $orange;
  border-color: $orange;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-inapt-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $white;
  border-color: $black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-active-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $blue;
  border-color: $blue;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-lapsed-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $purple;
  border-color: $purple;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-dormant-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $black;
  border-color: $black;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-churned-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $dark-grey;
  border-color: $dark-grey;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-invited-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #4a332d;
  border-color: #4a332d;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-rolzo-first {
  font-size: 12px;
  border-radius: 2px;
  font-weight: 700 !important;
  white-space: nowrap;
  background-color: rgba(black, 0.1);
  border-color: black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-fraud-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $red-2;
  border-color: $red-2;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
}
.rolzo-first-label-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: 700 !important;
  white-space: nowrap;
  background-color: rgba(black, 0.1);
  border-color: black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
  margin-bottom: 1rem;
  width: 6.8rem;
  border: solid 1px;
  display: flex;
}
.rolzo-first-label-prefence {
  font-size: 12px;
  border-radius: 2px;
  font-weight: 700 !important;
  white-space: nowrap;
  background-color: rgba(black, 0.1);
  border-color: black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
  margin-bottom: 1rem;
  width: 5.8rem;
  border: solid 1px;
  display: flex;
}

.rolzo-first-label-client:hover {
  cursor: pointer;
}

.rolzo-first-redirect {
  color: #a79779;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 1rem;
  text-transform: none;
}

.rolzo-first-redirect:hover {
  cursor: pointer;
}

.rolzo-first-label-mobile {
  font-size: 12px;
  border-radius: 50%;
  font-weight: 700 !important;
  white-space: nowrap;
  display: flex;
  border: 1px solid rgba($dark-grey, 0.25);
  color: black;
  padding: 0.54rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  height: 2.813rem;
  width: 2.813rem;
}

.driver-section-driver-files-name {
  font-size: 14px !important;
}

.warning-img {
  width: 1.3rem;
  height: 1.3rem;
  // margin-left: 0.5rem;
  /* margin-bottom: 4px; */
  margin-right: 0.8rem;
}

.modal-header-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  // border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-body-2 {
  padding: 2rem 5rem 4rem 5rem;
}

.modal-fields {
  background-color: rgba($dark-grey, 0.05);
}

.modal-message {
  font-size: 15px !important;
  color: $black;
  padding: 3rem 0rem 3rem 0rem;
}

.travel-doc-col {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.travel-doc-row {
  margin-left: 0.01rem !important;
  max-width: none !important;
  width: 100% !important;
}

.travel-doc-remove {
  cursor: pointer;
  position: relative;
  top: 30.5px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
}
@media only screen and (max-width: 768px) {
  .travel-doc-row .travel-doc-remove {
    margin-left: 8px;
    flex-shrink: 0;
  }
}

.dispatch-modal-button {
  display: flex;
  justify-content: end;
  width: 100%;
  border-top: 1px solid rgba($dark-grey, 0.25);
  padding-top: 1rem;
}
.two-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 1.2em; /* Adjust based on your font size and desired line height */
  max-height: 2.4em; /* Should be 2 times the line-height to accommodate two lines */
}
.partnerBadge {
  color: $white;
  background: $dark-grey-2;
  border-radius: 10px;
  max-width: 16px;
  min-width: 16px;
  text-align: center;
  font-family: $font-family-base;
  font-size: 10px;
  font-weight: bold;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding-top: 0.1rem;
}
.partnerBadgeBooking {
  color: $white;
  background: $dark-grey-2;
  border-radius: 10px;
  max-width: 21px;
  min-width: 21px;
  text-align: center;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  padding-top: 2px;
  justify-content: center;
}
.partner-badge-list {
  color: $white;
  background: $dark-grey-2;
  border-radius: 10px;
  font-weight: bold;
  font-size: 9px;
  font-family: $font-family-base;
  padding: 3px 3px 2px 3px;
  margin-left: 6px;
  text-align: center;
}
.border-grey-30 {
  border-color: rgba($dark-grey, 0.3);
}
.left-0 {
  left: 0;
}
.z-index-1 {
  z-index: 1;
}
.flight-banner-alert {
  display: flex;
  align-items: center;
  border: 1px solid #ffa500;
  border-radius: 8px;
  padding: 16px;
  background-color: #fafafa;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}
.flight-banner-icon {
  height: 20px;
  width: 20px;
  margin-left: -6px;
  margin-right: 10px;
}
.flight-banner-icon-mobile {
  height: 20px;
  width: 20px;
  margin-left: -6px;
  margin-right: 10px;
  margin-bottom: 7rem;
}
@media (max-width: 425px) {
  .flight-banner-icon-mobile {
    margin-bottom: 8.2rem;
  }
}
.flight-banner-message {
  flex: 1;
}

.reinstate-booking-div {
  cursor: pointer;
  display: inline-block;
  margin-left: 1rem;

  img {
    height: 1rem;
    width: 1rem;
  }

  span {
    color: #a79779;
    font-size: 14px;
    margin-left: 0.25rem;
  }
}

.mt-fourHalf {
  margin-top: 2rem;
  white-space: pre;
}

.mt-fourHalf-right {
  right: 35px;
}

.maxWidth100 {
  max-width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
