$datepicker__border-radius: 2px;
$datepicker__border-color: $black;
$datepicker__background-color: $light-2;
$datepicker__font-family: $font-family-base;
$datepicker__font-size: 13px;
$datepicker__selected-color: rgba($dark, 0.3);
$datepicker__text-color: $dark-2;
$datepicker__header-color: $dark-2;
$datepicker__item-size: 45px;
$datepicker__day-margin: 2px;

@import 'node_modules/react-datepicker/src/stylesheets/datepicker';

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__close-icon::after {
    padding-right: 12px;
    background-color: transparent !important;
    background-image: url('/images/close-icon-round.png');
    background-size: 20px 20px;
    background-position: center right 13px !important;
    background-repeat: no-repeat;
    padding-right: 30px;
  }

  // .react-datepicker__close-icon {
  //   padding-right: 12px;
  //   background-color: transparent !important;
  //   background-image: url('/images/close-icon-round.png');
  //   background-size: 20px 20px;
  //   background-position: center right 13px !important;
  //   background-repeat: no-repeat;
  //   padding-right: 30px;
  //   &.active {
  //     background-image: url('/images/close-icon-round.png');
  //   }
  // }
}

.react-datepicker-popper {
  // position: relative !important;
  transform: translate3d(0, 65px, 0) !important;
  width: 100%;
}
.partner-grid-container {
  .react-datepicker-popper {
    transform: translate3d(0, 40px, 0) !important;
  }
}
.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0;
}

.custom-datepicker-calendar {
  width: 100%;
  min-width: 150px;
  //box-shadow: $default-shadow;
  background-color: $light-2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 12px;
  border-color: rgba($black, 0.1);

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    top: 25px;
    border: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    &--previous {
      left: 40px;
      background-image: url('/images/icons/arrow-left.svg');
    }

    &--next {
      right: 40px;
      background-image: url('/images/icons/arrow-right.svg');
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .react-datepicker__month-container {
    background-color: $white;
    float: none;
    margin: auto;
    width: 100%;
    .react-datepicker__header {
      background-color: $white;
      border-bottom: none;
      margin: 0 0.4rem;
      .react-datepicker__current-month {
        font-size: 13px;
        margin-top: 4px;
      }
    }
  }
  .react-datepicker__header {
    padding-top: 16px;

    .react-datepicker__current-month {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    width: calc((100% / 7) - 8px);
    //width: 36px;
    font-size: 14px;
    margin: 1px;
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    //display: flex;
    align-items: center;
  }

  .react-datepicker__day {
    /*&:hover {
      color: $white !important;
      background-color: $brown;
    }*/
    @media (min-width: 768px) {
      &:hover {
        background-color: rgba($dark, 0.2);
        color: $black !important;
        opacity: 1 !important;
        font-weight: 900;
      }
    }

    &--selected {
      // background-color: rgba($black, 1);
      background-color: rgba($dark, 0.2);
      color: $black !important;
      opacity: 1 !important;
      font-weight: 900;

      &:hover {
        //background-color: $black;
        //color: $light !important;
        //font-weight: 500;
      }
    }
    &--today {
      // background-color: rgba($dark, 0.1);
      color: $black !important;
      font-weight: 900;
      &:hover {
        background-color: rgba($dark, 0.2);
        color: $black !important;
        opacity: 1 !important;
        font-weight: 900;
      }
    }
    &--disabled {
      &:hover {
        background-color: $light-2 !important;
        color: #ccc !important;
        opacity: 1 !important;
      }
    }
    /*&--keyboard-selected {
      color: $white !important;
      background-color: $brown;
    }*/
  }

  .react-datepicker__day--outside-month {
    opacity: 0.4;
  }

  .react-datepicker__day-name {
    line-height: 1;
    margin-top: 6px;
  }

  &.react-datepicker--time-only {
    width: 100%;
    .react-datepicker__time-container {
      min-width: 100px;
      width: 100%;
      border: none;

      .react-datepicker__header--time {
        display: none;
      }

      .react-datepicker__time-box {
        border-radius: 0;
        width: 100%;
        text-align: left;
      }

      .react-datepicker__time-list-item {
        // background-color: $light-2;
        background-color: $white;
        font-weight: 400;
        padding: 10px !important;
        display: flex;
        align-items: center;
        height: auto !important;

        &:hover {
          background-color: rgba($black, 0.1) !important;
          //color: $white;
          font-weight: 500;
        }

        &--selected {
          // background-color: $light-2 !important;
          background-color: $white !important;
          font-weight: 500 !important;
          color: $black !important;

          &:hover {
            //background-color: $black !important;
            //font-weight: 500 !important;
            //color: $white !important;
          }
        }
      }
    }
  }
}
